import superagent from 'superagent';
import { ActionType } from 'redux-promise-middleware';
import {
  GET_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
  SEND_INVOICE,
  GENERATE_INVOICE,
  VIEWING_AN_ORDER,
  GET_LINK_FOR_QUOTE_IMAGE,
} from 'redux/actions/orders';
import { getSession } from 'helpers/awsAuth';
import { showNotification } from 'redux/modules/app/appActions';
import config from 'config';

const { CoreURL } = config;

export const getOrders = (query = {}) => async (dispatch, getState) => {
  const { login } = getState();
  const { user = {} } = login;
  const isAdmin = Boolean(user.is_admin);

  dispatch({ type: `${GET_ORDERS}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get(`/api/v1/${isAdmin ? 'admin/' : ''}applications`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .query(query)
      .use(CoreURL);

    return dispatch({
      type: `${GET_ORDERS}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_ORDERS}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const createOrder = (data) => async (dispatch) => {
  dispatch({ type: `${CREATE_ORDER}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .post('/api/v1/requests')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(showNotification({ title: 'Success', message: 'Order create' }));

    return dispatch({
      type: `${CREATE_ORDER}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (err) {
    console.error(
      err,
      err.response ? JSON.parse(err.response.text) : err.message,
    );
    dispatch(showNotification({ title: 'Error', message: 'Failed to create' }));
    return dispatch({
      type: `${CREATE_ORDER}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const updateOrder = (id, data) => async (dispatch, getState) => {
  const { login } = getState();
  const { user = {} } = login;
  const isAdmin = Boolean(user.is_admin);

  dispatch({ type: `${UPDATE_ORDER}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .patch(`/api/v1/${isAdmin ? 'admin/' : ''}applications/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(showNotification({ title: 'Success', message: 'Order updated' }));

    return dispatch({
      type: `${UPDATE_ORDER}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (err) {
    console.error(
      err,
      err.response ? JSON.parse(err.response.text) : err.message,
    );
    dispatch(showNotification({ title: 'Error', message: 'Failed to update' }));
    return dispatch({
      type: `${UPDATE_ORDER}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const viewingAnOrder = (id) => async (dispatch) => {
  dispatch({ type: `${VIEWING_AN_ORDER}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    await superagent
      .patch(`/api/v1/admin/applications/${id}/seen`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    return dispatch({
      type: `${VIEWING_AN_ORDER}_${ActionType.Fulfilled}`,
      payload: id,
    });
  } catch (err) {
    console.error(
      err,
      err.response ? JSON.parse(err.response.text) : err.message,
    );

    return dispatch({
      type: `${VIEWING_AN_ORDER}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const getLinkForQuoteImage = (data) => async (dispatch) => {
  dispatch({ type: `${GET_LINK_FOR_QUOTE_IMAGE}_${ActionType.Pending}` });

  try {
    const result = await superagent
      .post('/api/v1/requests/attachment')
      .set('Content-Type', 'application/json')
      .use(CoreURL)
      .send(data);

    return dispatch({
      type: `${GET_LINK_FOR_QUOTE_IMAGE}_${ActionType.Fulfilled}`,
      payload: JSON.parse(result.text),
    });
  } catch (e) {
    return dispatch({
      type: `${GET_LINK_FOR_QUOTE_IMAGE}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const generateInvoice = (id, data) => async (dispatch) => {
  dispatch({ type: `${GENERATE_INVOICE}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .post(`/api/v1/admin/applications/${id}/invoice`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(
      showNotification({ title: 'Success', message: 'Invoice created' }),
    );

    return dispatch({
      type: `${GENERATE_INVOICE}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (err) {
    console.error(
      err,
      err.response ? JSON.parse(err.response.text) : err.message,
    );

    dispatch(
      showNotification({
        title: 'Error',
        message: 'Failed to generate invoice',
      }),
    );
    return dispatch({
      type: `${GENERATE_INVOICE}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const sendInvoice = (id) => async (dispatch) => {
  dispatch({ type: `${SEND_INVOICE}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .post(`/api/v1/admin/applications/invoice/send/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    dispatch(showNotification({ title: 'Success', message: 'Invoice sent' }));

    return dispatch({
      type: `${SEND_INVOICE}_${ActionType.Fulfilled}`,
      payload: JSON.parse(result.text),
    });
  } catch (err) {
    console.error(
      err,
      err.response ? JSON.parse(err.response.text) : err.message,
    );

    dispatch(
      showNotification({ title: 'Error', message: 'Failed to send invoice' }),
    );
    return dispatch({
      type: `${SEND_INVOICE}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};
