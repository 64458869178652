import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Snackbar,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { Info, Close } from '@material-ui/icons';
import { hideNotification } from 'redux/modules/app/appActions';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

const styles = () => ({
  notification: {
    transform: 'translateY(500px)',
    opacity: 0,
    transition: '0.6s all ease',
    width: '432px',
    right: '32px',
    bottom: '32px',
  },
  notificationOpened: {
    transform: 'translateY(0px)',
    opacity: 1,
  },
  snackbar: {
    backgroundColor: '#fff',
    width: '100%',
    padding: '14px 8px 14px 14px',
    alignItems: 'center',
    boxShadow: 'none',
    border: '1px solid #E2E8F0',
    borderRadius: '12px',
    flexWrap: 'inherit',
  },
  contentMessage: {
    maxWidth: '100%',
    padding: '0',
  },
  action: {
    margin: '0 0 0 auto',
    padding: 0,
  },
  icon: {
    width: '44px',
    height: '44px',
    marginRight: '16px',
    color: '#707070',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {},
  description: {},
  close: {},
});

const UserNotification = (props) => {
  const { classes } = props;

  const notification = useSelector((state) => state.app.notification);

  const dispatch = useDispatch();
  const onClose = () => dispatch(hideNotification());

  const { isOpened: isOpen, title, message } = notification;

  const notificationClassName = cn(classes.notification, {
    [classes.notificationOpened]: isOpen,
  });

  const onClickAwayClose = () => {
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const vertical = window.innerWidth > 960 ? 'bottom' : 'top';

  return (
    <Snackbar
      className={notificationClassName}
      anchorOrigin={{
        vertical,
        horizontal: 'right',
      }}
      open={isOpen}
      // autoHideDuration={5000}
      onClose={onClose}
      ClickAwayListenerProps={{
        onClickAway: onClickAwayClose,
      }}
      ContentProps={{
        'aria-describedby': 'message-id',
        classes: {
          root: classes.snackbar,
          message: classes.contentMessage,
          action: classes.action,
        },
      }}
      message={
        <div id="message-id" className={classes.message}>
          <Info className={classes.icon} />
          <Box component="div">
            <Typography
              type="subheading"
              color="primary"
              variant="subtitle1"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              className={classes.description}
              variant="subtitle2"
              color="primary"
            >
              {message}
            </Typography>
          </Box>
        </div>
      }
      action={[
        <IconButton
          disableRipple
          key="curandcords-key"
          onClick={onClose}
          color="secondary"
          className={classes.close}
        >
          <Close />
        </IconButton>,
      ]}
    />
  );
};

UserNotification.propTypes = propTypes;

export default withStyles(styles)(UserNotification);
