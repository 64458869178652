import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';

const propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  component: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  exact: false,
};

const styles = (theme) => ({
  container: {
    padding: '72px 100px',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LoginPage = (props) => {
  const { path, exact, component, classes } = props;

  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const isFirstSignIn = useSelector((state) => state.login.isFirstSignIn);
  const lastRoute = useSelector((state) => state.app.lastRoute);

  return isAuthenticated && !isFirstSignIn ? (
    <Redirect to={lastRoute ? lastRoute : ROUTES.ROOT} />
  ) : (
    <div className={classes.container}>
      <Route path={path} component={component} exact={exact} />
    </div>
  );
};

LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;

const enhance = compose(withStyles(styles), withRouter);

export default enhance(LoginPage);
