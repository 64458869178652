import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
  Button,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { disableUser } from 'redux/modules/users/usersActions';
import { getSorting, stableSort } from 'helpers/functions';
import EditUser from './../Modals/EditUser';
import CreateUserAdmin from './../Modals/CreateUserAdmin';
import DeleteConfirmation from './../Modals/DeleteConfirmation';
// import MoreActions from 'uikits/MoreActions';
import SHAPES from 'shapes';

const propTypes = {
  users: SHAPES.USERS_SHAPE,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleOrderBy: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  isAdminPage: PropTypes.bool,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  users: [],
  isAdminPage: false,
};

const styles = () => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
  },
  table: {},
  cell: {
    color: '#707070',
  },
  cellName: {
    color: '#707070',
    cursor: 'pointer',
  },
  checkbox: {
    color: '#707070',
  },
  pagination: {
    marginLeft: 'auto',
  },
  btns: {
    marginLeft: 'auto',
  },
  button: {
    marginLeft: 10,
  },
  buttonFilter: {
    marginLeft: 10,
    marginRight: -12,
  },
});

const UsersTable = (props) => {
  const {
    classes,
    users,
    page,
    rowsPerPage,
    orderBy,
    order,
    handleOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    isAdminPage,
  } = props;
  const [selectedIds, setSelectedIds] = useState([]);
  const [isCreateAdmin, setIsCreateAdmin] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDisableUsers, setIsDisableUsers] = useState(false);
  const [selectUser, setSelectUser] = useState(null);

  const user = useSelector((state) => state.login.user);

  const isSuperAdmin = useMemo(() => {
    return Boolean(user && user.is_admin && user.is_system_admin);
  }, [user]);

  const dispatch = useDispatch();
  const DisableUser = (id) => dispatch(disableUser(id));

  const tableUsers = useMemo(() => {
    return stableSort(users, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [users, order, orderBy, page, rowsPerPage]);

  const nameUsers = useMemo(() => {
    return selectedIds.map((id) => {
      const foundUser = users.find((u) => u._id === id);

      return foundUser ? `${foundUser.first_name} ${foundUser.last_name}` : id;
    });
  }, [users, selectedIds]);

  const handleSelectUser = (id) => {
    const isItemSelected = selectedIds.findIndex((uId) => uId === id) !== -1;

    if (isItemSelected) {
      setSelectedIds(selectedIds.filter((uId) => uId !== id));
    } else {
      setSelectedIds(selectedIds.concat(id));
    }
  };

  const handleChangeTablePage = () => {
    handleChangePage();
    setSelectedIds([]);
    setIsDisableUsers(false);
  };

  const handleDisableUsers = () => {
    selectedIds.forEach((id) => {
      handleDisableUser(id);
    });
    setSelectedIds([]);
    setIsDisableUsers(false);
  };

  const handleDisableUser = (id) => {
    DisableUser(id);
    setSelectedIds([]);
  };

  const handleCreateAdminOpen = () => {
    setIsCreateAdmin(true);
  };

  const handleCloseAdminOpen = () => {
    setIsCreateAdmin(false);
  };

  const handleEditOpen = (user) => {
    setIsEditOpen(true);
    setSelectUser(user);
  };

  const handleIsEditClose = () => {
    setIsEditOpen(false);
    setSelectUser(null);
  };

  const handleIsDisableUsersOpen = () => {
    setIsDisableUsers(true);
  };

  const handleIsDisableUsersClose = () => {
    setIsDisableUsers(false);
  };

  return (
    <Fragment>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          {isAdminPage ? 'Admins' : 'Users'}
        </Typography>

        <div className={classes.btns}>
          {selectedIds.length > 0 && (
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleIsDisableUsersOpen}
            >
              {`Disable ${selectedIds.length} users`}
            </Button>
          )}

          {isAdminPage && isSuperAdmin && (
            <Button
              variant="outlined"
              className={classes.button}
              color="secondary"
              onClick={handleCreateAdminOpen}
            >
              Add User Admin
            </Button>
          )}
        </div>
      </Toolbar>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox"></TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'first_name' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'first_name'}
                direction={order}
                onClick={() => handleOrderBy('first_name')}
              >
                First name
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'last_name' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'last_name'}
                direction={order}
                onClick={() => handleOrderBy('last_name')}
              >
                Last name
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'email' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'email'}
                direction={order}
                onClick={() => handleOrderBy('email')}
              >
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'phone_number' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'phone_number'}
                direction={order}
                onClick={() => handleOrderBy('phone_number')}
              >
                Phone
              </TableSortLabel>
            </TableCell>
            {/* <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'is_admin' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'is_admin'}
                direction={order}
                onClick={() => handleOrderBy('is_admin')}
              >
                Admin
              </TableSortLabel>
            </TableCell> */}
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'is_invite_sent' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'is_invite_sent'}
                direction={order}
                onClick={() => handleOrderBy('is_invite_sent')}
              >
                Is invite sent
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'status' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'status'}
                direction={order}
                onClick={() => handleOrderBy('status')}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={
                orderBy === 'address.address_primary' ? order : false
              }
            >
              <TableSortLabel
                active={orderBy === 'address.address_primary'}
                direction={order}
                onClick={() => handleOrderBy('address.address_primary')}
              >
                Address
              </TableSortLabel>
            </TableCell>
            {/* <TableCell></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableUsers.map((user, index) => {
            const labelId = `user-${index}`;
            const isItemSelected =
              selectedIds.findIndex((id) => id === user._id) !== -1;

            return (
              <TableRow
                className={classes.productRow}
                hover
                role="checkbox"
                tabIndex={-1}
                key={user._id}
                aria-checked={isItemSelected}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    className={classes.checkbox}
                    checked={isItemSelected}
                    onChange={() => handleSelectUser(user._id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.first_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.last_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.email}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.phone_number}
                </TableCell>
                {/* <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {String(user.is_admin)}
                </TableCell> */}
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {String(user.is_invite_sent)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.status}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user && user.address && user.address.address_primary
                    ? user.address.address_primary
                    : ''}
                </TableCell>
                {/* <TableCell align="center" padding="none">
                  <MoreActions
                    actions={[
                      {
                        label: 'Edit',
                        func: () => handleEditOpen(user),
                      },
                      {
                        label: 'Disable',
                        func: () => handleDisableUser(user._id),
                      },
                    ]}
                  />
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangeTablePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <EditUser
        user={selectUser}
        isOpen={isEditOpen}
        handleClose={handleIsEditClose}
      />

      <CreateUserAdmin
        isOpen={isCreateAdmin}
        handleClose={handleCloseAdminOpen}
      />

      <DeleteConfirmation
        isOpen={isDisableUsers}
        title={`Are you sure you want to block ${nameUsers.length} users?`}
        text={'Users who will be blocked:'}
        nameUsers={nameUsers}
        handleClose={handleIsDisableUsersClose}
        handleConfirm={handleDisableUsers}
      />
    </Fragment>
  );
};

UsersTable.propTypes = propTypes;
UsersTable.defaultProps = defaultProps;

export default withStyles(styles)(UsersTable);
