import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Radio, IconButton } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { DatePicker, TimePicker } from '@material-ui/pickers';

const propTypes = {
  date: PropTypes.instanceOf(Date),
  time_from: PropTypes.instanceOf(Date),
  time_to: PropTypes.instanceOf(Date),
  selected: PropTypes.bool,
  isView: PropTypes.bool,
  isAdmin: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  date: null,
  time_from: null,
  time_to: null,
  isAdmin: false,
  selected: false,
  isView: false,
};

const styles = () => ({
  times: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18,
    position: 'relative',
  },
  date: {
    width: '44%',
    marginRight: '2%',
  },
  timesItems: {
    width: '54%',
    display: 'flex',
  },
  time: {
    width: '49%',
    marginRight: '2%',
    '& input': {
      paddingLeft: 14,
      paddingRight: 14,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  btns: {
    position: 'absolute',
    top: '50%',
    left: '100%',
    marginLeft: -1,
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  radio: {
    padding: 2,
  },
  removeBtn: {
    padding: 2,
  },
});

const TimeOrder = (props) => {
  const {
    classes,
    date,
    time_from,
    time_to,
    selected,
    isView,
    isAdmin,
    handleChange,
    handleRemove,
  } = props;

  const minDatePicker = useMemo(() => {
    return new Date();
  }, []);

  return (
    <div className={classes.times}>
      <DatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        placeholder="Date"
        inputVariant="outlined"
        color="secondary"
        className={classes.date}
        value={date}
        minDate={isView ? undefined : minDatePicker}
        onChange={(e) => handleChange('date', e)}
        disabled={isView}
      />
      <div className={classes.timesItems}>
        <TimePicker
          variant="inline"
          placeholder="From"
          inputVariant="outlined"
          color="secondary"
          value={time_from}
          className={classes.time}
          onChange={(e) => handleChange('time_from', e)}
          disabled={isView}
        />
        <TimePicker
          variant="inline"
          placeholder="Until"
          inputVariant="outlined"
          color="secondary"
          value={time_to}
          className={classes.time}
          onChange={(e) => handleChange('time_to', e)}
          disabled={isView}
        />
      </div>

      <div className={classes.btns}>
        {isAdmin && (
          <Radio
            disabled={isView}
            checked={selected}
            className={classes.radio}
            onChange={() => handleChange('selected', !selected)}
          />
        )}

        <IconButton
          className={classes.removeBtn}
          onClick={handleRemove}
          color="secondary"
          disabled={isView}
          size="small"
        >
          <HighlightOff />
        </IconButton>
      </div>
    </div>
  );
};

TimeOrder.propTypes = propTypes;
TimeOrder.defaultProps = defaultProps;

export default withStyles(styles)(TimeOrder);
