import { LOGOUT } from 'redux/actions/login';
import {
  UPDATE_REVIEW,
  DELETE_REVIEW,
  GET_REVIEWS,
} from 'redux/actions/reviews';
import { ActionType } from 'redux-promise-middleware';
import { loadState } from './../../store/localStorage';

const initialState = loadState();

const { reviews: cachedReviews = [], reviewsCount = 0 } = initialState || {};

const defaultState = {
  reviews: cachedReviews,
  count: reviewsCount,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // case `${DELETE_REVIEW}_${ActionType.Pending}`:
    case `${UPDATE_REVIEW}_${ActionType.Pending}`:
    case `${GET_REVIEWS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${GET_REVIEWS}_${ActionType.Rejected}`:
    case `${UPDATE_REVIEW}_${ActionType.Rejected}`:
      // case `${DELETE_REVIEW}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${GET_REVIEWS}_${ActionType.Fulfilled}`: {
      const reviews = JSON.parse(action.payload.text || '[]');

      return {
        reviews,
        count: reviews.length,
        isLoading: false,
      };
    }

    case `${UPDATE_REVIEW}_${ActionType.Fulfilled}`: {
      return handleUpdateReview(state, action.payload);
    }

    case `${DELETE_REVIEW}_${ActionType.Fulfilled}`: {
      return handleDeleteReview(state, action.payload);
    }

    case LOGOUT: {
      return {
        ...defaultState,
        reviews: [],
        count: 0,
      };
    }

    default:
      return state;
  }
};

const handleUpdateReview = (state, payload) => {
  const review = JSON.parse(payload.text || '{}');
  const reviews = state.reviews.map((u) => {
    return u._id === review._id ? review : u;
  });

  return {
    ...state,
    reviews,
    isLoading: false,
  };
};

const handleDeleteReview = (state, id) => {
  const reviews = state.reviews.filter((u) => u._id !== id);

  return {
    ...state,
    reviews,
    isLoading: false,
  };
};
