import superagent from 'superagent';
import { ActionType } from 'redux-promise-middleware';
import { getSession } from 'helpers/awsAuth';
import {
  GET_EMPLOYEES,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
} from 'redux/actions/employees';
import { showNotification } from 'redux/modules/app/appActions';
import config from 'config';

const { CoreURL } = config;

export const getEmployees = () => async (dispatch) => {
  dispatch({ type: `${GET_EMPLOYEES}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get('/api/v1/admin/employees')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    return dispatch({
      type: `${GET_EMPLOYEES}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_EMPLOYEES}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const createEmployee = (data) => async (dispatch) => {
  dispatch({ type: `${CREATE_EMPLOYEE}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .post('/api/v1/admin/employees')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(
      showNotification({
        title: 'Success',
        message: 'New employee has been added',
      }),
    );

    return dispatch({
      type: `${CREATE_EMPLOYEE}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (err) {
    console.error(err);
    dispatch(showNotification({ title: 'Error', message: 'Failed to create' }));
    return dispatch({
      type: `${CREATE_EMPLOYEE}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const updateEmployee = (id, data) => async (dispatch) => {
  dispatch({ type: `${UPDATE_EMPLOYEE}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .patch(`/api/v1/admin/employees/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(
      showNotification({ title: 'Success', message: 'Employee updated' }),
    );

    return dispatch({
      type: `${UPDATE_EMPLOYEE}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    dispatch(showNotification({ title: 'Error', message: 'Failed to update' }));
    return dispatch({
      type: `${UPDATE_EMPLOYEE}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const deleteEmployee = (id, data) => async (dispatch) => {
  dispatch({ type: `${DELETE_EMPLOYEE}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    await superagent
      .delete(`/api/v1/admin/employees/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    return dispatch({
      type: `${DELETE_EMPLOYEE}_${ActionType.Fulfilled}`,
      payload: id,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${DELETE_EMPLOYEE}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};
