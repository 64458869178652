import configureStore from './configureStore';
import { saveState } from './localStorage';

const store = configureStore();

store.subscribe(() => {
  saveState({
    user: store.getState().login.user,
    orders: store.getState().orders.orders,
    ordersCount: store.getState().orders.count,
    users: store.getState().users.users,
    usersCount: store.getState().users.count,
    employees: store.getState().employees.employees,
    employeesCount: store.getState().employees.count,
    reviews: store.getState().reviews.reviews,
    reviewsCount: store.getState().reviews.count,
  });
});

export default store;
