import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
  Button,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { deleteEmployee } from 'redux/modules/employees/employeesActions';
import { getSorting, stableSort } from 'helpers/functions';
import CreateEditEmployee from '../Modals/CreateEditEmployee';
import DeleteConfirmation from './../Modals/DeleteConfirmation';
import SHAPES from 'shapes';

const propTypes = {
  users: SHAPES.EMPLOYEES_SHAPE,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleOrderBy: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  users: [],
};

const styles = () => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
  },
  table: {},
  cell: {
    color: '#707070',
  },
  cellName: {
    color: '#707070',
    cursor: 'pointer',
  },
  checkbox: {
    color: '#707070',
  },
  pagination: {
    marginLeft: 'auto',
  },
  btns: {
    marginLeft: 'auto',
  },
  button: {
    marginLeft: 10,
  },
  buttonFilter: {
    marginLeft: 10,
    marginRight: -12,
  },
});

const EmployeesTable = (props) => {
  const {
    classes,
    users,
    page,
    rowsPerPage,
    orderBy,
    order,
    handleOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const [selectedIds, setSelectedIds] = useState([]);
  const [isDisableUsers, setIsDisableUsers] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [selectUser, setSelectUser] = useState(null);

  const dispatch = useDispatch();
  const DeleteEmployee = (id) => dispatch(deleteEmployee(id));

  const isEditModal = useMemo(() => {
    return selectUser && Boolean(selectUser._id);
  }, [selectUser]);

  const handleCreateOpen = () => {
    setIsCreateEdit(true);
    setSelectUser(null);
  };

  const tableUsers = useMemo(() => {
    return stableSort(users, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [users, order, orderBy, page, rowsPerPage]);

  const nameUsers = useMemo(() => {
    return selectedIds.map((id) => {
      const foundUser = users.find((u) => u._id === id);

      return foundUser ? `${foundUser.first_name} ${foundUser.last_name}` : id;
    });
  }, [users, selectedIds]);

  const handleSelectUser = (id) => {
    const isItemSelected = selectedIds.findIndex((uId) => uId === id) !== -1;

    if (isItemSelected) {
      setSelectedIds(selectedIds.filter((uId) => uId !== id));
    } else {
      setSelectedIds(selectedIds.concat(id));
    }
  };

  const handleRemove = () => {
    selectedIds.forEach((id) => {
      DeleteEmployee(id);
    });
    setSelectedIds([]);
    setIsDisableUsers(false);
  };

  const handleEditOpen = (user) => {
    setIsCreateEdit(true);
    setSelectUser(user);
  };

  const handleIsEditClose = () => {
    setIsCreateEdit(false);
  };

  const handleIsDisableUsersOpen = () => {
    setIsDisableUsers(true);
  };

  const handleIsDisableUsersClose = () => {
    setIsDisableUsers(false);
  };

  return (
    <Fragment>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          Users
        </Typography>

        <div className={classes.btns}>
          {selectedIds.length > 0 && (
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleIsDisableUsersOpen}
            >
              {`Delete ${selectedIds.length} employees`}
            </Button>
          )}

          <Button
            variant="outlined"
            className={classes.button}
            color="secondary"
            onClick={handleCreateOpen}
          >
            Add Employee
          </Button>
        </div>
      </Toolbar>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox"></TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'first_name' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'first_name'}
                direction={order}
                onClick={() => handleOrderBy('first_name')}
              >
                First name
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'last_name' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'last_name'}
                direction={order}
                onClick={() => handleOrderBy('last_name')}
              >
                Last name
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'job_title' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'job_title'}
                direction={order}
                onClick={() => handleOrderBy('job_title')}
              >
                Job title
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableUsers.map((user, index) => {
            const labelId = `user-${index}`;
            const isItemSelected =
              selectedIds.findIndex((id) => id === user._id) !== -1;

            return (
              <TableRow
                className={classes.productRow}
                hover
                role="checkbox"
                tabIndex={-1}
                key={user._id}
                aria-checked={isItemSelected}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    className={classes.checkbox}
                    checked={isItemSelected}
                    onChange={() => handleSelectUser(user._id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.first_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.last_name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(user)}
                  className={classes.cellName}
                >
                  {user.job_title}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <CreateEditEmployee
        isOpen={isCreateEdit}
        isEdit={isEditModal}
        user={selectUser}
        handleClose={handleIsEditClose}
      />

      <DeleteConfirmation
        isOpen={isDisableUsers}
        title={`Are you sure you want to delete ${nameUsers.length} employees?`}
        text={'Employees who will be deleted:'}
        nameUsers={nameUsers}
        handleClose={handleIsDisableUsersClose}
        handleConfirm={handleRemove}
      />
    </Fragment>
  );
};

EmployeesTable.propTypes = propTypes;
EmployeesTable.defaultProps = defaultProps;

export default withStyles(styles)(EmployeesTable);
