import superagent from 'superagent';
import { ActionType } from 'redux-promise-middleware';
import config from 'config';
import {
  LOGIN,
  LOGOUT,
  USER_INFO,
  UPDATE_USER_INFO,
  USER_FORGOT_PASSWORD,
  // USER_FORGOT_PASSWORD_SUBMIT,
  // USER_FORGOT_PASSWORD_SUBMIT_ERROR,
  USER_REGISTRATION,
  USER_AUTHENTICATE,
  USER_AUTHENTICATE_ERROR,
} from 'redux/actions/login';
import {
  signIn as AwsSignIn,
  forgotPassword as AwsForgotPassword,
  // forgotPasswordSubmit as AwsForgotPasswordSubmit,
  completeNewPassword,
  getSession,
  authenticate as AwsAuthenticate,
  signOut as AwsSignOut,
} from 'helpers/awsAuth';

const { CoreURL } = config;

export const userRegistration = (data) => async (dispatch) => {
  const payload = superagent
    .post('/api/v1/auth/register')
    .set('Content-Type', 'application/json')
    .use(CoreURL)
    .send(data);

  return dispatch({
    type: USER_REGISTRATION,
    payload,
  });
};

export const signIn = (email, password) => async (dispatch) => {
  const payload = await AwsSignIn(email, password);

  // const token1 = await getSession();
  // const authenticate1 = await authenticate();
  // console.log('token1', token1)
  // console.log('authenticate1', authenticate1)

  // if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
  //   dispatch({
  //     type: USER_FIRST_SIGNIN,
  //     payload: {
  //       isRequiredPassword: true,
  //       dataUser: result,
  //     }
  //   })
  // }

  const token = payload?.signInUserSession?.accessToken?.jwtToken;

  if (token) {
    const myInfo = await superagent
      .get('/api/v1/profile/me')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    dispatch({
      type: USER_INFO,
      payload: myInfo,
    });
  }

  return dispatch({
    type: LOGIN,
    payload,
  });
};

export const getMyInfoUser = () => async (dispatch) => {
  dispatch({ type: `${USER_INFO}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;

    if (token) {
      const result = await superagent
        .get('/api/v1/profile/me')
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`)
        .use(CoreURL);

      return dispatch({
        type: USER_INFO,
        payload: result,
      });
    }
  } catch (err) {
    console.error(
      err,
      err.response ? JSON.parse(err.response.text) : err.message,
    );
    return dispatch({
      type: `${USER_INFO}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const firstSignIn = (newPassword) => async (dispatch, getState) => {
  const { login } = getState();
  const { awsUser } = login;

  const payload = await completeNewPassword(awsUser, newPassword);

  return dispatch({
    type: LOGIN,
    payload,
  });
};

export const authenticate = () => async (dispatch) => {
  try {
    const payload = await AwsAuthenticate();
    const token = payload?.signInUserSession?.accessToken?.jwtToken;

    const myInfo = await superagent
      .get('/api/v1/profile/me')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    dispatch({
      type: USER_INFO,
      payload: myInfo,
    });

    dispatch({
      type: USER_AUTHENTICATE,
      payload,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: USER_AUTHENTICATE_ERROR,
    });
    // message.error(err?.message)
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  const result = await AwsForgotPassword(email);
  console.log('forgotPassword', result, email);

  return dispatch({
    type: USER_FORGOT_PASSWORD,
    payload: result.CodeDeliveryDetails.Destination,
  });
};

// export const forgotPasswordSubmit = (email, code, new_password) => async dispatch => {
//   try {
//     const payload = await AwsForgotPasswordSubmit(email, code, new_password)
//     console.log('forgotPasswordSubmit', payload);

//     return dispatch({ type: USER_FORGOT_PASSWORD_SUBMIT });
//   } catch(err) {
//     console.error(err)
//     return dispatch({ type: USER_FORGOT_PASSWORD_SUBMIT_ERROR });
//   }
// }

// export const signOut = () => async dispatch => {
//   try {
//     await api.user.signOut()
//     clearLocalStorage()

//     dispatch({
//       type: USER_AUTHENTICATE,
//       isAuthenticated: false
//     })

//     window.location.reload()
//   } catch(err) {
//     message.error(err?.message)
//   }
// }

export const logout = () => async (dispatch) => {
  try {
    await AwsSignOut();

    return dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateUserInfo = (data) => async (dispatch) => {
  const session = await getSession();
  const token = session.idToken.jwtToken;
  const result = superagent
    .patch('/api/v1/profile/')
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .use(CoreURL)
    .send(data);

  return dispatch({
    type: UPDATE_USER_INFO,
    payload: result,
  });
};
