import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { LogoIcon } from 'icons';

const styles = (theme) => ({
  logo: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    width: 38,
    height: 40,
    marginRight: 12,
    [theme.breakpoints.down('md')]: {
      width: 34,
      height: 36,
    },
  },
  text: {
    fontWeight: 700,
    color: '#F46F04',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
});

const Logo = (props) => {
  const { classes } = props;

  return (
    <div className={classes.logo}>
      <LogoIcon className={classes.icon} />
      <Typography className={classes.text} component="span" variant="h4">
        СandСords
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Logo);
