import React, { useMemo, useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { withStyles, Paper, Grid, TextField, Button } from '@material-ui/core';
import { AssignmentInd } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  getMyInfoUser,
  updateUserInfo,
} from 'redux/modules/login/loginActions';
import { showNotification } from 'redux/modules/app/appActions';
import TextMaskPhone from 'uikits/TextMaskPhone';

const styles = () => ({
  paper: {
    maxWidth: 760,
    padding: '32px 20px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 100,
    marginRight: 24,
  },
  field: {
    marginBottom: '18px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 18,
  },
});

function ProfilePage(props) {
  const { classes } = props;
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    address: '',
  });

  const dataUser = useSelector((state) => state.login.user);
  const userEmail = dataUser.email ? dataUser.email : '';

  const isAdmin = useMemo(() => {
    return Boolean(dataUser.is_admin);
  }, [dataUser]);

  const dispatch = useDispatch();
  const GetMyInfoUser = () => dispatch(getMyInfoUser());
  const UpdateUserInfo = (data) => dispatch(updateUserInfo(data));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  useEffect(() => {
    clearStates();
    GetMyInfoUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearStates = () => {
    setUser({
      first_name: dataUser && dataUser.first_name ? dataUser.first_name : '',
      last_name: dataUser && dataUser.last_name ? dataUser.last_name : '',
      phone_number:
        dataUser && dataUser.phone_number ? dataUser.phone_number : '',
      address:
        dataUser && dataUser.address && dataUser.address.address_primary
          ? dataUser.address.address_primary
          : '',
    });
  };

  const handleChange = (name) => (event) => {
    setUser({ ...user, [name]: event.target.value });
  };

  const handleUpdate = async () => {
    const data = { ...user };
    delete data.address;

    data.address = {
      address_primary: user.address,
      address_optional: '',
      post_code: '',
      city: '',
      country: 'USA',
    };
    try {
      await UpdateUserInfo(data);
    } catch (err) {
      console.error(err);
      ShowNotification('Error', 'Failed to update your user profile');
    }
  };

  return (
    <DocumentTitle
      title={`CandCords ${
        isAdmin ? 'Admin Dashboard' : 'Personal Account'
      } - Profile`}
    >
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <AssignmentInd className={classes.icon} color="secondary" />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                color="secondary"
                label="First name"
                value={user.first_name}
                className={classes.field}
                onChange={handleChange('first_name')}
                fullWidth
              />

              <TextField
                variant="outlined"
                color="secondary"
                label="Last name"
                value={user.last_name}
                className={classes.field}
                onChange={handleChange('last_name')}
                fullWidth
              />

              <TextField
                variant="outlined"
                color="secondary"
                label="Email"
                disabled
                value={userEmail}
                className={classes.field}
                onChange={handleChange('email')}
                fullWidth
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                variant="outlined"
                color="secondary"
                label="Phone"
                value={user.phone_number}
                className={classes.field}
                onChange={handleChange('phone_number')}
                name="phone"
                InputProps={{
                  inputComponent: TextMaskPhone,
                }}
                fullWidth
              />

              <TextField
                variant="outlined"
                color="secondary"
                label="Address"
                value={user.address}
                className={classes.field}
                onChange={handleChange('address')}
                fullWidth
              />
            </Grid>
            <Grid className={classes.buttons} item md={12}>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={clearStates}
              >
                Clear
              </Button>
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                size="large"
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DocumentTitle>
  );
}

export default withStyles(styles)(ProfilePage);
