import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  withStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SHAPES from 'shapes';
import { updateUser } from 'redux/modules/users/usersActions';
import { showNotification } from 'redux/modules/app/appActions';
import TextMaskPhone from 'uikits/TextMaskPhone';

const propTypes = {
  isOpen: PropTypes.bool,
  user: SHAPES.USER_SHAPE,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
  user: null,
};

const styles = () => ({
  modal: {
    maxWidth: '732px',
    width: '732px',
  },
  title: {
    marginBottom: 6,
  },
  field: {
    marginBottom: '18px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  checkbox: {},
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
});

const EditUser = (props) => {
  const { isOpen, user: propsUser, handleClose, classes } = props;
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    is_admin: false,
    status: 'active',
    address: '',
  });

  const loginUser = useSelector((state) => state.login.user);

  const isSuperAdmin = useMemo(() => {
    return Boolean(
      loginUser && loginUser.is_admin && loginUser.is_system_admin,
    );
  }, [loginUser]);

  const dispatch = useDispatch();
  const UpdateUser = (id, data) => dispatch(updateUser(id, data));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  useEffect(() => {
    clearModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, propsUser]);

  const clearModal = () => {
    setUser({
      first_name: propsUser && propsUser.first_name ? propsUser.first_name : '',
      last_name: propsUser && propsUser.last_name ? propsUser.last_name : '',
      email: propsUser && propsUser.email ? propsUser.email : '',
      phone_number:
        propsUser && propsUser.phone_number ? propsUser.phone_number : '',
      is_admin: propsUser && propsUser.is_admin ? propsUser.is_admin : false,
      status: propsUser && propsUser.status ? propsUser.status : 'active',
      address:
        propsUser && propsUser.address && propsUser.address.address_primary
          ? propsUser.address.address_primary
          : '',
    });
  };

  const handleChange = (name) => (event) => {
    setUser({ ...user, [name]: event.target.value });
  };

  const handleToggleIsAdmin = () => {
    setUser({ ...user, is_admin: !user.is_admin });
  };

  const handleUpdate = async () => {
    const data = { ...user };
    delete data.address;

    data.address = {
      address_primary: user.address,
      address_optional: '',
      post_code: '',
      city: '',
      country: 'USA',
    };

    try {
      await UpdateUser(propsUser._id, data);
      handleClose();
    } catch (err) {
      console.error(err);
      ShowNotification('Error', 'Failed to update user');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography className={classes.title} variant="h5">
          Edit user
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.row}>
          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="First name"
              value={user.first_name}
              className={classes.field}
              onChange={handleChange('first_name')}
              fullWidth
            />

            <TextField
              variant="outlined"
              color="secondary"
              label="Last name"
              value={user.last_name}
              className={classes.field}
              onChange={handleChange('last_name')}
              fullWidth
            />
          </Grid>

          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="Phone"
              value={user.phone_number}
              className={classes.field}
              onChange={handleChange('phone_number')}
              name="phone"
              InputProps={{
                inputComponent: TextMaskPhone,
              }}
              fullWidth
            />

            <TextField
              variant="outlined"
              color="secondary"
              label="Email"
              disabled
              value={user.email}
              className={classes.field}
              onChange={handleChange('email')}
              fullWidth
            />
          </Grid>

          <Grid item md={12} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="Address"
              value={user.address}
              className={classes.field}
              onChange={handleChange('address')}
              fullWidth
            />
          </Grid>

          <Grid item md={12} className={classes.col}>
            <FormControlLabel
              className={classes.checkbox}
              disabled={!isSuperAdmin}
              control={
                <Checkbox
                  checked={user.is_admin}
                  onChange={handleToggleIsAdmin}
                />
              }
              label="Admin"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleUpdate}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditUser.propTypes = propTypes;
EditUser.defaultProps = defaultProps;

export default withStyles(styles)(EditUser);
