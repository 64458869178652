import PropTypes from 'prop-types';

export const USER_SHAPE = PropTypes.shape({
  _id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  sub: PropTypes.string,
  status: PropTypes.string,
  address: PropTypes.shape({
    address_primary: PropTypes.string,
    address_optional: PropTypes.string,
    post_code: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  is_system_admin: PropTypes.bool,
  is_invite_sent: PropTypes.bool,
});

export const USERS_SHAPE = PropTypes.arrayOf(USER_SHAPE);
