import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  withStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SHAPES from 'shapes';
import {
  createEmployee,
  updateEmployee,
  getEmployees,
} from 'redux/modules/employees/employeesActions';
import { showNotification } from 'redux/modules/app/appActions';

const propTypes = {
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  user: SHAPES.EMPLOYEE_SHAPE,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
  isEdit: false,
  user: null,
};

const styles = () => ({
  modal: {
    maxWidth: '732px',
    width: '732px',
  },
  title: {
    marginBottom: 6,
  },
  field: {
    marginBottom: '18px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  checkbox: {},
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
});

const CreateEditEmployee = (props) => {
  const { isOpen, user: propsUser, handleClose, isEdit, classes } = props;
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    job_title: '',
  });

  const titleModal = useMemo(() => {
    if (isEdit) {
      return 'Edit Employee';
    }
    return 'Crate Employee';
  }, [isEdit]);

  const isDisabledUpdate = useMemo(() => {
    return Boolean(
      !user.first_name.trim() ||
        !user.last_name.trim() ||
        !user.job_title.trim(),
    );
  }, [user]);

  const dispatch = useDispatch();
  const GetEmployees = () => dispatch(getEmployees());
  const UpdateEmployee = (id, data) => dispatch(updateEmployee(id, data));
  const CreateEmployee = (data) => dispatch(createEmployee(data));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  useEffect(() => {
    clearModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, propsUser]);

  const clearModal = () => {
    setUser({
      first_name: propsUser && propsUser.first_name ? propsUser.first_name : '',
      last_name: propsUser && propsUser.last_name ? propsUser.last_name : '',
      job_title: propsUser && propsUser.job_title ? propsUser.job_title : '',
    });
  };

  const handleChange = (name) => (event) => {
    setUser({ ...user, [name]: event.target.value });
  };

  const handleUpdate = async () => {
    try {
      if (isEdit) {
        await UpdateEmployee(propsUser._id, user);
      } else {
        await CreateEmployee(user);
      }
      closeAfterSending();
    } catch (err) {
      console.error(err);
      ShowNotification('Error', 'Failed to update user');
    }
  };

  const closeAfterSending = () => {
    GetEmployees();
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography className={classes.title} variant="h5">
          {titleModal}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.row}>
          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="First name"
              value={user.first_name}
              className={classes.field}
              onChange={handleChange('first_name')}
              fullWidth
            />

            <TextField
              variant="outlined"
              color="secondary"
              label="Last name"
              value={user.last_name}
              className={classes.field}
              onChange={handleChange('last_name')}
              fullWidth
            />
          </Grid>

          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="Job title"
              value={user.job_title}
              className={classes.field}
              onChange={handleChange('job_title')}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleUpdate}
          disabled={isDisabledUpdate}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateEditEmployee.propTypes = propTypes;
CreateEditEmployee.defaultProps = defaultProps;

export default withStyles(styles)(CreateEditEmployee);
