import superagent from 'superagent';
import { ActionType } from 'redux-promise-middleware';
import { getSession } from 'helpers/awsAuth';
import {
  GET_USERS,
  GET_ADMIN_USERS,
  ADMIN_UPDATE_USER,
  DISABLE_USER,
  ADD_USER_ADMIN,
} from 'redux/actions/users';
import { showNotification } from 'redux/modules/app/appActions';
import config from 'config';

const { CoreURL } = config;

export const getUsers = () => async (dispatch) => {
  dispatch({ type: `${GET_USERS}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get('/api/v1/admin/users')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    return dispatch({
      type: `${GET_USERS}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_USERS}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const getAdminUsers = () => async (dispatch) => {
  dispatch({ type: `${GET_ADMIN_USERS}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get(`/api/v1/system_admin/users`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    return dispatch({
      type: `${GET_ADMIN_USERS}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_ADMIN_USERS}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  dispatch({ type: `${ADMIN_UPDATE_USER}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .patch(`/api/v1/admin/users/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    return dispatch({
      type: `${ADMIN_UPDATE_USER}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${ADMIN_UPDATE_USER}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const disableUser = (id) => async (dispatch) => {
  dispatch({ type: `${DISABLE_USER}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    await superagent
      .patch(`/api/v1/admin/users/${id}/disable`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    return dispatch({
      type: `${DISABLE_USER}_${ActionType.Fulfilled}`,
      payload: id,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${DISABLE_USER}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const addUserAdmin = (data) => async (dispatch) => {
  dispatch({ type: `${ADD_USER_ADMIN}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .post(`/api/v1/system_admin/users`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(
      showNotification({
        title: 'Success',
        message: 'New admin has been added',
      }),
    );

    return dispatch({
      type: `${ADD_USER_ADMIN}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    dispatch(showNotification({ title: 'Error', message: 'Failed to create' }));
    return dispatch({
      type: `${ADD_USER_ADMIN}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};
