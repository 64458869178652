import { LOGOUT } from 'redux/actions/login';
import { GET_ORDERS, VIEWING_AN_ORDER } from 'redux/actions/orders';
import { ActionType } from 'redux-promise-middleware';
import { loadState } from './../../store/localStorage';

const initialState = loadState();

const { orders: cachedOrders = [], ordersCount = 0 } = initialState || {};

const defaultState = {
  orders: cachedOrders,
  count: ordersCount,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_ORDERS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${GET_ORDERS}_${ActionType.Fulfilled}`: {
      const { items: orders = [], count = 0 } = JSON.parse(
        action.payload.text || '{ orders: [], count: 0 }',
      );

      return {
        orders,
        count,
        isLoading: false,
      };
    }

    case `${VIEWING_AN_ORDER}_${ActionType.Fulfilled}`: {
      return handleViewingAnOrder(state, action.payload);
    }

    case `${GET_ORDERS}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    case LOGOUT: {
      return {
        orders: [],
        count: 0,
        ...defaultState,
      };
    }

    default:
      return state;
  }
};

const handleViewingAnOrder = (state, id) => {
  return {
    ...state,
    orders: state.orders.map((order) =>
      order._id === id ? { ...order, is_seen: true } : order,
    ),
  };
};
