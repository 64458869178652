import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TableCell,
  TableSortLabel,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

const propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  isAdmin: PropTypes.bool,
  onlyAdmin: PropTypes.bool,
  enableFilter: PropTypes.bool,
  handleOrderBy: PropTypes.func.isRequired,
  dataFilter: PropTypes.shape({}),
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  ),
  handleChangeDataFilter: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  orders: [],
  name: '',
  label: '',
  isAdmin: false,
  onlyAdmin: false,
  enableFilter: false,
  statusOptions: [],
  employees: [],
  dataFilter: {},
};

const styles = () => ({
  col: {
    verticalAlign: 'top',
  },
  colContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  textFieldWrp: {
    width: '100%',
  },
  colHeadDate: {
    width: '48.5%',
    marginTop: 6,
    marginRight: '3%',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  colHeadInput: {
    marginTop: 6,
  },
});

const TableCellHead = (props) => {
  const {
    classes,
    orderBy,
    order,
    name,
    label,
    isAdmin,
    onlyAdmin,
    enableFilter,
    handleOrderBy,
    isFilter,
    dataFilter,
    statusOptions,
    employees,
    handleChangeDataFilter,
  } = props;

  if (onlyAdmin && !isAdmin) {
    return null;
  }

  return (
    <TableCell
      align="left"
      padding="default"
      className={classes.col}
      style={{ maxWidth: name === 'created_at' ? 242 : '' }}
      sortDirection={orderBy === name ? order : false}
    >
      <div className={classes.colContent}>
        <TableSortLabel
          active={orderBy === name}
          direction={order}
          onClick={() => handleOrderBy(name)}
          className={classes.sortLabel}
        >
          {label}
        </TableSortLabel>
        {isFilter && enableFilter && (
          <>
            {name === 'created_at' && (
              <div className={classes.textFieldWrp}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  placeholder="Date from"
                  size="small"
                  inputVariant="outlined"
                  className={classes.colHeadDate}
                  value={dataFilter.from_date || null}
                  onChange={(e) =>
                    handleChangeDataFilter('from_date')({
                      target: { value: e },
                    })
                  }
                />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  placeholder="Date to"
                  size="small"
                  inputVariant="outlined"
                  className={classes.colHeadDate}
                  value={dataFilter.to_date || null}
                  onChange={(e) =>
                    handleChangeDataFilter('to_date')({ target: { value: e } })
                  }
                />
              </div>
            )}
            {name === 'status' && (
              <TextField
                select
                variant="outlined"
                // label="Status"
                size="small"
                value={dataFilter.status || ' '}
                className={classes.colHeadInput}
                onChange={handleChangeDataFilter('status')}
                fullWidth
              >
                <MenuItem button value={' '}>
                  Any status
                </MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} button value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {name === 'employee_id' && (
              <TextField
                select
                variant="outlined"
                // label="Status"
                size="small"
                value={dataFilter.employee_id || ' '}
                className={classes.colHeadInput}
                onChange={handleChangeDataFilter('employee_id')}
                fullWidth
              >
                <MenuItem button value={' '}>
                  Any employee
                </MenuItem>
                {employees.map((option) => (
                  <MenuItem key={option._id} button value={option._id}>
                    {option.first_name || ''} {option.last_name || ''}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {name !== 'status' &&
              name !== 'created_at' &&
              name !== 'employee_id' && (
                <TextField
                  value={dataFilter[name] || ''}
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.colHeadInput}
                  placeholder={label}
                  onChange={handleChangeDataFilter(name)}
                />
              )}
          </>
        )}
      </div>
    </TableCell>
  );
};

TableCellHead.propTypes = propTypes;
TableCellHead.defaultProps = defaultProps;

export default withStyles(styles)(TableCellHead);
