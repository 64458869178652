import PropTypes from 'prop-types';

export const REVIEW_SHAPE = PropTypes.shape({
  _id: PropTypes.string,
  reviewer: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  text: PropTypes.string,
  stats: PropTypes.number,
  source: PropTypes.string,
  is_published: PropTypes.bool,
});

export const REVIEWS_SHAPE = PropTypes.arrayOf(REVIEW_SHAPE);
