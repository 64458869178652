import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

function HomePage(props) {
  const { classes } = props;

  return <div className={classes.container}>home</div>;
}

export default withStyles(styles)(HomePage);
