import PropTypes from 'prop-types';

export const ORDER_SHAPE = PropTypes.shape({
  _id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  status: PropTypes.string,
  available_time: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      selected: PropTypes.bool,
    }),
  ),
  is_asap: PropTypes.bool,
  address: PropTypes.shape({
    address_primary: PropTypes.string,
    address_optional: PropTypes.string,
    post_code: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  // user_id: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
});

export const ORDERS_SHAPE = PropTypes.arrayOf(ORDER_SHAPE);
