import React from 'react';
import { useSelector } from 'react-redux';
import FirstSignIn from './FirstSignIn';
import SignIn from './SignIn';

const LoginPage = () => {
  const isFirstSignIn = useSelector((state) => state.login.isFirstSignIn);

  return isFirstSignIn ? <FirstSignIn /> : <SignIn />;
};

export default LoginPage;
