import { LOGOUT } from 'redux/actions/login';
import {
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_EMPLOYEES,
} from 'redux/actions/employees';
import { ActionType } from 'redux-promise-middleware';
import { loadState } from './../../store/localStorage';

const initialState = loadState();

const { employees: cachedEmployees = [], employeesCount = 0 } =
  initialState || {};

const defaultState = {
  employees: cachedEmployees,
  count: employeesCount,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // case `${DELETE_EMPLOYEE}_${ActionType.Pending}`:
    case `${UPDATE_EMPLOYEE}_${ActionType.Pending}`:
    case `${GET_EMPLOYEES}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${GET_EMPLOYEES}_${ActionType.Rejected}`:
    case `${UPDATE_EMPLOYEE}_${ActionType.Rejected}`:
      // case `${DELETE_EMPLOYEE}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${GET_EMPLOYEES}_${ActionType.Fulfilled}`: {
      const employees = JSON.parse(action.payload.text || '[]');
      return {
        employees,
        count: employees.length,
        isLoading: false,
      };
    }

    case `${UPDATE_EMPLOYEE}_${ActionType.Fulfilled}`: {
      return handleUpdateEmployee(state, action.payload);
    }

    case `${DELETE_EMPLOYEE}_${ActionType.Fulfilled}`: {
      return handleDeleteEmployee(state, action.payload);
    }

    case LOGOUT: {
      return {
        ...defaultState,
        employees: [],
        count: 0,
      };
    }

    default:
      return state;
  }
};

const handleUpdateEmployee = (state, payload) => {
  const employee = JSON.parse(payload.text || '{}');
  const employees = state.employees.map((u) => {
    return u._id === employee._id ? employee : u;
  });

  return {
    ...state,
    employees,
    isLoading: false,
  };
};

const handleDeleteEmployee = (state, id) => {
  const employees = state.employees.filter((u) => u._id !== id);

  return {
    ...state,
    employees,
    isLoading: false,
  };
};
