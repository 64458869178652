import React, { useState, useMemo, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Grid,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { groupBy } from 'lodash';
import DocumentTitle from 'react-document-title';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import {
  getSummaryStatistic,
  getEarningsStatistic,
} from 'redux/modules/statistic/statisticActions';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import useDebounce from 'uikits/useDebounce';
import optionsStatus from 'constants/optionsStatus';
import { getEmployees } from 'redux/modules/employees/employeesActions';
import { enumerateDaysBetweenDates } from 'helpers/functions';

const styles = () => ({
  paper: {
    padding: 20,
    minHeight: '100%',
  },
  title: {
    marginBottom: 24,
  },
  textFieldWrp: {
    // width: 380,
    marginBottom: 24,
  },
  chartWrp: {
    position: 'relative',
    width: '100%',
    height: 326,
    marginBottom: 24,
    // height: 410,
  },
  chart: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  pieChart: {
    maxHeight: '100%',
    minHeight: '100%',
  },
  legends: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  legend: {
    width: '48%',
    marginRight: '4%',
    '& span': {
      display: 'inline-block',
      width: 10,
      height: 10,
      marginRight: 8,
    },
    '&:nth-of-type(2n)': {
      marginRight: 0,
    },
  },
  colHeadInput: {
    width: 194,
    marginRight: 22,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  barWrp: {
    position: 'relative',
    width: '100%',
    height: 410,
  },
  bar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  barChart: {
    maxHeight: '100%',
    minHeight: '100%',
  },
});

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#FFA07A',
  '#FF69B4',
  '#00FFFF',
  '#7B68EE',
  '#7FFFD4',
];

function StatisticPage(props) {
  const { classes } = props;
  const [statusFilter, setStatusFilter] = useState({
    from_date: '',
    to_date: new Date(),
  });
  const [earningsFilter, setEarningsFilter] = useState({
    employee_id: '',
    from_date: '',
    to_date: new Date(),
  });

  const maxDatePicker = useMemo(() => {
    return new Date();
  }, []);

  const debouncedStatusFilter = useDebounce(statusFilter, 1000);
  const debouncedEarningsFilter = useDebounce(earningsFilter, 1000);

  const summary = useSelector((state) => state.statistic.summary);
  const earnings = useSelector((state) => state.statistic.earnings);
  const employees = useSelector((state) => state.employees.employees);

  const dispatch = useDispatch();
  const GetSummaryStatistic = (query) => dispatch(getSummaryStatistic(query));
  const GetEarningsStatistic = (query) => dispatch(getEarningsStatistic(query));
  const GetEmployees = () => dispatch(getEmployees());

  useEffect(() => {
    GetEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {};
    if (debouncedStatusFilter.from_date) {
      data.from_date = moment(debouncedStatusFilter.from_date)
        .startOf('day')
        ._d.toISOString();
    }
    if (debouncedStatusFilter.to_date) {
      data.to_date = moment(debouncedStatusFilter.to_date)
        .startOf('day')
        ._d.toISOString();
    }
    GetSummaryStatistic(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStatusFilter]);

  useEffect(() => {
    const data = {};
    if (debouncedEarningsFilter.from_date) {
      data.from_date = moment(debouncedEarningsFilter.from_date)
        .startOf('day')
        ._d.toISOString();
    }
    if (debouncedEarningsFilter.to_date) {
      data.to_date = moment(debouncedEarningsFilter.to_date)
        .startOf('day')
        ._d.toISOString();
    }
    if (debouncedEarningsFilter.employee_id.trim()) {
      data.employee_id = debouncedEarningsFilter.employee_id;
    }
    GetEarningsStatistic(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEarningsFilter]);

  const rawData = useMemo(() => {
    return Array.isArray(summary)
      ? summary.map((s) => ({
          name: s.status,
          value: s.count,
        }))
      : [];
  }, [summary]);

  const rawEarnings = useMemo(() => {
    if (!Array.isArray(earnings) && earnings.length) {
      return [];
    }
    const maxDate = earnings.reduce((date, e) => {
      const nDate = new Date(e._id.year, e._id.month - 1, e._id.day);
      if (+nDate > +date) {
        return nDate;
      }
      return date;
    }, 0);
    const minDate = earnings.reduce((date, e) => {
      const nDate = new Date(e._id.year, e._id.month - 1, e._id.day);
      if (+nDate < +date) {
        return nDate;
      }
      return date;
    }, maxDate);

    const rangeDates = enumerateDaysBetweenDates(minDate, maxDate);

    return rangeDates.map((date) => {
      const foundEarning = earnings.find(
        (e) =>
          moment(new Date(e._id.year, e._id.month - 1, e._id.day)).format(
            'YYYY-MM-DD',
          ) === date,
      );
      const month = moment(date).format('MMMM, YYYY');

      return {
        name: date,
        date,
        month,
        total: foundEarning ? foundEarning.total : 0,
      };
    });
  }, [earnings]);

  const dataEarnings = useMemo(() => {
    if (rawEarnings.length <= 31) {
      return rawEarnings;
    }
    const groupMonth = groupBy(rawEarnings, 'month');

    const keys = Object.keys(groupMonth);
    const values = Object.values(groupMonth);

    return values.map((arr, index) => ({
      name: keys[index],
      // date: keys[index],
      total: arr.reduce((result, e) => {
        return result + e.total;
      }, 0),
    }));
  }, [rawEarnings]);
  console.log('rawEarnings, dataEarnings', rawEarnings, dataEarnings);

  const renderYAxis = useMemo(() => {
    const maxTotal =
      Math.ceil(
        dataEarnings.reduce((result, e) => {
          return e.total > result ? e.total : result;
        }, 0) / 100,
      ) * 100;

    const ticks = [];
    for (let x = 1, count = Math.ceil(maxTotal / 250); x <= count; x++) {
      ticks.push(x * 250);
    }

    return dataEarnings.length > 0 ? (
      <YAxis
        type="number"
        padding={{ top: 10 }}
        tickFormatter={(tick) => `$${tick}`}
        allowDuplicateCategory={false}
        ticks={ticks}
      />
    ) : (
      <YAxis type="number" ticks={[0, 100]} />
    );
  }, [dataEarnings]);

  const handleChangeStatusFilter = (name, value) => {
    setStatusFilter({ ...statusFilter, [name]: value });
  };

  const handleChangeEarningsFilter = (name, value) => {
    setEarningsFilter({ ...earningsFilter, [name]: value });
  };

  const getTextStatus = (status) => {
    const foundStatus = optionsStatus.find((s) => s.value === status);

    return foundStatus ? foundStatus.text : status;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const RADIAN = Math.PI / 180;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y - 7}
          fontSize="13px"
          fontWeight="400"
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            textShadow: '2px 2px 4px #000',
          }}
        >
          {getTextStatus(name)}
        </text>
        <text
          x={x}
          y={y + 7}
          fontSize="13px"
          fontWeight="400"
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            textShadow: '2px 2px 4px #000',
          }}
        >
          {value}
        </text>
      </>
    );
  };

  const tickFormatterX = (tick, index) => {
    if (!tick) {
      return dataEarnings[index].name;
    }
    return moment(tick).format('ddd, D MMM');
  };

  const tooltipFormatter = (total) => `$${total.toFixed(2)}`;

  const tooltipLabelFormatter = (d, e) => {
    if (!d) {
      return (e && e[0] && e[0].payload && e[0].payload.name) || '';
    }

    return moment(d).format('ddd, D MMM');
  };

  return (
    <DocumentTitle title="CandCords Admin Dashboard - Statistic">
      <Grid container spacing={4}>
        <Grid item md="auto">
          <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h6">
              Statistics on statuses
            </Typography>

            <div className={classes.textFieldWrp}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="Date from"
                size="small"
                inputVariant="outlined"
                className={classes.colHeadInput}
                value={statusFilter.from_date || null}
                maxDate={statusFilter.to_date || null}
                onChange={(e) => handleChangeStatusFilter('from_date', e)}
              />
              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="Date to"
                size="small"
                inputVariant="outlined"
                className={classes.colHeadInput}
                value={statusFilter.to_date || null}
                minDate={statusFilter.from_date || null}
                maxDate={maxDatePicker}
                onChange={(e) => handleChangeStatusFilter('to_date', e)}
              />
            </div>

            <div className={classes.chartWrp}>
              {rawData.length ? (
                <ResponsiveContainer debounce={300} className={classes.chart}>
                  <PieChart className={classes.pieChart}>
                    <Pie
                      data={rawData}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      innerRadius={80}
                      outerRadius={160}
                      stroke="none"
                      dataKey="value"
                    >
                      {rawData.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <Typography variant="body1">
                  No data available to display
                </Typography>
              )}
            </div>

            <div className={classes.legends}>
              {rawData.map((item, index) => (
                <Typography
                  className={classes.legend}
                  component="p"
                  variant="body1"
                  key={item.name}
                >
                  <span
                    className={classes.legendColor}
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  {getTextStatus(item.name)}
                </Typography>
              ))}
            </div>
          </Paper>
        </Grid>
        <Grid item md={true}>
          <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h6">
              Earnings statistics
            </Typography>

            <div className={classes.textFieldWrp}>
              <TextField
                select
                variant="outlined"
                // label="Status"
                size="small"
                value={earningsFilter.employee_id || ' '}
                className={classes.colHeadInput}
                onChange={(e) =>
                  handleChangeEarningsFilter('employee_id', e.target.value)
                }
              >
                <MenuItem button value={' '}>
                  Any employee
                </MenuItem>
                {employees.map((option) => (
                  <MenuItem key={option._id} button value={option._id}>
                    {option.first_name || ''} {option.last_name || ''}
                  </MenuItem>
                ))}
              </TextField>

              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="Date from"
                size="small"
                inputVariant="outlined"
                className={classes.colHeadInput}
                value={earningsFilter.from_date || null}
                maxDate={earningsFilter.to_date || null}
                onChange={(e) => handleChangeEarningsFilter('from_date', e)}
              />

              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="Date to"
                size="small"
                inputVariant="outlined"
                className={classes.colHeadInput}
                value={earningsFilter.to_date || null}
                minDate={earningsFilter.from_date || null}
                maxDate={maxDatePicker}
                onChange={(e) => handleChangeEarningsFilter('to_date', e)}
              />
            </div>

            <div className={classes.barWrp}>
              <ResponsiveContainer debounce={300} className={classes.bar}>
                <BarChart data={dataEarnings} className={classes.barChart}>
                  {renderYAxis}
                  <XAxis dataKey="date" tickFormatter={tickFormatterX} />
                  <CartesianGrid strokeDasharray="5 5" stroke="#CBD5E0" />
                  <Tooltip
                    formatter={tooltipFormatter}
                    labelFormatter={tooltipLabelFormatter}
                    position={{ y: 200 }}
                    itemStyle={{ color: '#FFFFFF', fontSize: '14px' }}
                    cursor={{ fill: '#c8c9d243' }}
                    contentStyle={{
                      backgroundColor: '#303E4E',
                      border: 'none',
                      borderRadius: '10px',
                      padding: '10px 15px',
                    }}
                    labelStyle={{ color: '#A0AEC0', fontSize: '14px' }}
                  />
                  <Bar dataKey="total" fill={COLORS[0]} stackId="a" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </DocumentTitle>
  );
}

export default withStyles(styles)(StatisticPage);
