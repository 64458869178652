import React, { useState } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ROUTES from 'constants/routes';
import { signIn } from 'redux/modules/login/loginActions';
import { showNotification } from 'redux/modules/app/appActions';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const styles = (theme) => ({
  col: {
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 26,
  },
  textField: {
    width: '100%',
    marginBottom: 15,
  },
  text: {
    textAlign: 'center',
  },
  button: {
    width: '50%',
    marginTop: 20,
  },
  line: {
    width: '2px',
    height: '260px',
    margin: '74px 160px 0',
    backgroundColor: theme.palette.primary.secondary,
  },
});

const LoginPage = (props) => {
  const { classes, history } = props;
  const [data, setData] = useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const SignIn = (email, password) => dispatch(signIn(email, password));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  const handleChangeData = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleDataClickShowPassword = () => {
    setData({ ...data, showPassword: !data.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      await SignIn(data.email, data.password);
      history.push('/');
    } catch (err) {
      console.error(err);
      ShowNotification('Error', err.response ? err.response.text : err.message);
      setLoading(false);
    }
  };

  return (
    <div className={classes.col}>
      <Typography className={classes.title} variant="h3" color="secondary">
        Sign In
      </Typography>
      <TextField
        className={classes.textField}
        value={data.email}
        onChange={handleChangeData('email')}
        label="Email"
        variant="outlined"
        name="email"
        color="secondary"
      />
      <FormControl className={classes.textField} variant="outlined">
        <InputLabel id="login-password">Password</InputLabel>
        <OutlinedInput
          type={data.showPassword ? 'text' : 'password'}
          value={data.password}
          color="secondary"
          onChange={handleChangeData('password')}
          aria-describedby="login-password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleDataClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                color="secondary"
                edge="end"
              >
                {data.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          variant="outlined"
        />
        {loading && <LinearProgress color="secondary" />}
      </FormControl>

      <Typography className={classes.text} variant="subtitle2">
        If you don't have an account, try{' '}
        <Typography component={Link} to={ROUTES.SIGN_UP} color="secondary">
          registering
        </Typography>
      </Typography>

      <Typography className={classes.text} variant="subtitle2">
        If you forgot your password, try to{' '}
        <Typography
          component={Link}
          to={ROUTES.RESTORE_PASSWORD}
          color="secondary"
        >
          restore
        </Typography>{' '}
        it
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        onClick={handleLogin}
        size="large"
        color="secondary"
      >
        Submit
      </Button>
    </div>
  );
};

const enhance = compose(withStyles(styles), withRouter);

export default enhance(LoginPage);
