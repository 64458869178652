import { LOGOUT } from 'redux/actions/login';
import {
  ADMIN_UPDATE_USER,
  GET_USERS,
  GET_ADMIN_USERS,
  DISABLE_USER,
} from 'redux/actions/users';
import { ActionType } from 'redux-promise-middleware';

const defaultState = {
  users: [],
  count: 0,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${ADMIN_UPDATE_USER}_${ActionType.Pending}`:
    case `${GET_USERS}_${ActionType.Pending}`:
    case `${GET_ADMIN_USERS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${ADMIN_UPDATE_USER}_${ActionType.Rejected}`:
    case `${GET_USERS}_${ActionType.Rejected}`:
    case `${GET_ADMIN_USERS}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${GET_USERS}_${ActionType.Fulfilled}`: {
      return handleGetUsers(
        state,
        action.payload.text || '{ users: [], count: 0 }',
      );
    }

    case `${GET_ADMIN_USERS}_${ActionType.Fulfilled}`: {
      return handleGetUsers(
        state,
        action.payload.text || '{ users: [], count: 0 }',
      );
    }

    case `${ADMIN_UPDATE_USER}_${ActionType.Fulfilled}`: {
      return handleUpdateUser(state, action.payload);
    }

    case `${DISABLE_USER}_${ActionType.Fulfilled}`: {
      return handleDisableUser(state, action.payload);
    }

    case LOGOUT: {
      return {
        ...defaultState,
      };
    }

    default:
      return state;
  }
};

const handleGetUsers = (state, payload) => {
  let countDisabledUser = 0;
  const { items = [], count = 0 } = JSON.parse(payload);
  const users = items.filter((u) => {
    const isFilter = Boolean(u.status !== 'disabled');
    if (isFilter) {
      countDisabledUser += 1;
    }
    return isFilter;
  });

  return {
    ...state,
    users,
    count: count - countDisabledUser,
    isLoading: false,
  };
};

const handleUpdateUser = (state, payload) => {
  const user = JSON.parse(payload.text || '{}');
  const users = state.users.map((u) => {
    return u._id === user._id ? user : u;
  });

  return {
    ...state,
    users,
    isLoading: false,
  };
};

const handleDisableUser = (state, id) => {
  let countDisabledUser = 0;
  const users = state.users.filter((u) => {
    const isFilter = u._id !== id;
    if (isFilter) {
      countDisabledUser += 1;
    }
    return isFilter;
  });

  return {
    ...state,
    users,
    count: state.count - countDisabledUser,
    isLoading: false,
  };
};
