const data = [
  {
    value: 'new',
    text: 'New',
  },
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'waiting_for_payment',
    text: 'Waiting for payment',
  },
  {
    value: 'paid',
    text: 'Paid',
  },
  {
    value: 'completed',
    text: 'Completed',
  },
  {
    value: 'rejected',
    text: 'Rejected',
  },
];

export default data;
