import React, { useState, useMemo, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import SHAPES from 'shapes';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  TablePagination,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import superagent from 'superagent';
import { getSession } from 'helpers/awsAuth';
import { FilterList } from '@material-ui/icons';
import { getSorting, stableSort } from 'helpers/functions';
import optionsStatus from 'constants/optionsStatus';
import optionsCategory from 'constants/optionsCategory';
import { getOrders } from 'redux/modules/orders/ordersActions';
import useDebounce from 'uikits/useDebounce';
import config from 'config';
import CreateEditOrder from './../Modals/CreateEditOrder/CreateEditOrder';
import EditUser from './../Modals/EditUser';
import SuccessPayment from './../Modals/SuccessPayment';
import TableCellHead from './ui-kits/TableCellHead';

const { CoreURL } = config;

const propTypes = {
  orders: SHAPES.ORDERS_SHAPE,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleOrderBy: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  orders: [],
};

const styles = () => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    // fontWeight: 400,
    // fontSize: 18,
  },
  table: {},
  productRowNotViewed: {
    backgroundColor: 'rgba(244, 111, 4, 0.1)',
    '& $cellName': {
      fontWeight: 600,
    },
  },
  colContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  cell: {
    color: '#707070',
  },
  statusRejected: {},
  cellName: {
    color: '#707070',
    cursor: 'pointer',
  },
  checkbox: {
    color: '#707070',
  },
  pagination: {
    marginLeft: 'auto',
  },
  btns: {
    marginLeft: 'auto',
  },
  button: {
    marginLeft: 10,
  },
  buttonFilter: {
    marginLeft: 10,
    marginRight: -12,
  },
});

const dataRow = [
  {
    name: 'category',
    label: 'Category',
  },
  {
    name: 'status',
    label: 'Status',
    enableFilter: true,
  },
  {
    name: 'employee_id',
    label: 'Employee',
    onlyAdmin: true,
    enableFilter: true,
  },
  {
    name: 'available_time',
    label: 'Available time',
  },
  {
    name: 'is_asap',
    label: 'ASAP',
  },
  {
    name: 'address.address_primary',
    label: 'Address',
  },
  {
    name: 'created_at',
    label: 'Created time',
    enableFilter: true,
  },
  {
    name: 'updated_at',
    label: 'Updated time',
  },
  {
    name: 'user.email',
    label: 'User email',
    onlyAdmin: true,
  },
];

const OrdersTable = (props) => {
  const {
    classes,
    orders,
    page,
    rowsPerPage,
    orderBy,
    order,
    handleOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const [selectUser, setSelectUser] = useState(null);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [selectOrder, setSelectOrder] = useState(null);
  const [orderInvoiceId, setOrderInvoiceId] = useState('');
  const [isFilter, setIsFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({});
  const [isSuccessPayment, setIsSuccessPayment] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const invoiceId = searchParams.get('invoiceId');

    const getOrder = async () => {
      if (id && invoiceId) {
        try {
          const session = await getSession();
          const token = session.idToken.jwtToken;
          const result = await superagent
            .get(`/api/v1/applications/${id}`)
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${token}`)
            .use(CoreURL);

          if (result.body && result.body._id) {
            setSelectOrder(result.body);
            setOrderInvoiceId(invoiceId);
            setIsCreateEdit(true);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedDataFilter = useDebounce(dataFilter, 1000);

  useEffect(() => {
    const data = {};
    if (debouncedDataFilter.status && debouncedDataFilter.status.trim()) {
      data.status = debouncedDataFilter.status;
    }
    if (
      debouncedDataFilter.employee_id &&
      debouncedDataFilter.employee_id.trim()
    ) {
      data.employee_id = debouncedDataFilter.employee_id;
    }
    if (debouncedDataFilter.from_date) {
      data.from_date = moment(debouncedDataFilter.from_date)
        .startOf('day')
        ._d.toISOString();
    }
    if (debouncedDataFilter.to_date) {
      data.to_date = moment(debouncedDataFilter.to_date)
        .startOf('day')
        ._d.toISOString();
    }
    GetOrders(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDataFilter]);

  const user = useSelector((state) => state.login.user);
  const employees = useSelector((state) => state.employees.employees);

  const dispatch = useDispatch();
  const GetOrders = async (data) => dispatch(getOrders(data));

  const isAdmin = useMemo(() => {
    return Boolean(user.is_admin);
  }, [user]);

  const tableOrders = useMemo(() => {
    return stableSort(orders, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [orders, order, orderBy, page, rowsPerPage]);

  const isEditModal = useMemo(() => {
    return selectOrder && Boolean(selectOrder._id);
  }, [selectOrder]);

  const isViewModal = useMemo(() => {
    return (
      selectOrder &&
      selectOrder.status !== 'new' &&
      selectOrder.status !== 'active'
    );
  }, [selectOrder]);

  const titleModal = useMemo(() => {
    if (isViewModal) {
      return 'View Order';
    }
    if (isEditModal) {
      return 'Edit Order';
    }
    return 'Create Order';
  }, [isEditModal, isViewModal]);

  const handleCreateOpen = () => {
    setIsCreateEdit(true);
    setSelectOrder(null);
  };

  const handleEditOpen = (order) => {
    setIsCreateEdit(true);
    setSelectOrder(order);
  };

  const handleIsCreateEditClose = () => {
    setIsCreateEdit(false);
    setOrderInvoiceId('');
    setSelectOrder(null);
  };

  const handleEditUserOpen = (user) => {
    setIsEditUserOpen(true);
    setSelectUser(user);
  };

  const handleIsEditUserClose = () => {
    setIsEditUserOpen(false);
    setSelectUser(null);
  };

  const getNameCategory = (category) => {
    const foundCategory = optionsCategory.find((s) => s.value === category);

    return <span>{foundCategory ? foundCategory.text : category}</span>;
  };

  const getStatusColor = (status) => {
    let color = '';
    switch (status) {
      case 'new':
        color = '#CCCC00';
        break;
      case 'active':
        color = '#0033FF';
        break;
      case 'waiting_for_payment':
        color = '#FF9900';
        break;
      case 'paid':
        color = '#33CC33';
        break;
      case 'completed':
        color = '#CC00CC';
        break;
      case 'rejected':
        color = '#CC0000';
        break;
      default:
        color = '';
        break;
    }
    const foundStatus = optionsStatus.find((s) => s.value === status);

    return (
      <span style={{ color }}>{foundStatus ? foundStatus.text : status}</span>
    );
  };

  const renderTime = (times) => {
    const foundTime = (Array.isArray(times) ? times : []).find(
      (time) => time.selected,
    );

    return foundTime
      ? `${moment(foundTime.from).format('MM.DD.YYYY hh:mm')} - ${moment(
          foundTime.to,
        ).format('MM.DD.YYYY hh:mm')}`
      : '';
  };

  const renderEmployee = (id) => {
    const foundEmployee = employees.find((e) => e._id === id);

    if (foundEmployee) {
      return `${foundEmployee.first_name || ''} ${
        foundEmployee.last_name || ''
      }`;
    }
    return id;
  };

  const handleChangeDataFilter = (name) => (event) => {
    setDataFilter({ ...dataFilter, [name]: event.target.value });
  };

  const handleIsFilter = () => {
    if (!isFilter) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
      setDataFilter({});
    }
  };

  const onSuccessPayment = () => {
    setIsSuccessPayment(true);
  };

  const handleCloseSuccessPayment = () => {
    setIsSuccessPayment(false);
  };

  return (
    <Fragment>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          Orders
        </Typography>

        <div className={classes.btns}>
          {!isAdmin && (
            <Button
              variant="outlined"
              className={classes.button}
              color="secondary"
              onClick={handleCreateOpen}
            >
              Add order
            </Button>
          )}

          <Tooltip title="Filter list">
            <IconButton
              className={cn(classes.button, classes.buttonFilter)}
              onClick={handleIsFilter}
            >
              <FilterList />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {dataRow.map((col) => (
              <TableCellHead
                name={col.name}
                label={col.label}
                orderBy={orderBy}
                order={order}
                onlyAdmin={Boolean(col.onlyAdmin)}
                enableFilter={Boolean(col.enableFilter)}
                isAdmin={isAdmin}
                handleOrderBy={handleOrderBy}
                isFilter={isFilter}
                dataFilter={dataFilter}
                employees={employees}
                handleChangeDataFilter={handleChangeDataFilter}
                statusOptions={optionsStatus}
                key={col.name}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableOrders.map((order, index) => {
            const labelId = `order-${index}`;

            return (
              <TableRow
                className={cn(
                  isAdmin && !order.is_seen && classes.productRowNotViewed,
                )}
                hover
                role="checkbox"
                tabIndex={-1}
                key={order._id}
              >
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  {getNameCategory(order.category)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  {getStatusColor(order.status)}
                </TableCell>
                {isAdmin && (
                  <TableCell
                    component="th"
                    scope="row"
                    padding="default"
                    id={labelId}
                    onClick={() => handleEditOpen(order)}
                    className={classes.cellName}
                  >
                    {renderEmployee(order.employee_id)}
                  </TableCell>
                )}
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  {renderTime(order.available_time)}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  <span style={{ color: order.is_asap ? '#FF9900' : '' }}>
                    {order.is_asap ? 'Yes' : 'No'}
                  </span>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  {order && order.address && order.address.address_primary
                    ? order.address.address_primary
                    : ''}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  {moment(order.created_at).format('MM.DD.YYYY hh:mm')}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(order)}
                  className={classes.cellName}
                >
                  {moment(order.updated_at).format('MM.DD.YYYY hh:mm')}
                </TableCell>
                {isAdmin && (
                  <TableCell
                    component="th"
                    scope="row"
                    padding="default"
                    id={labelId}
                    onClick={() => {
                      if (order.user && order.user.email) {
                        handleEditUserOpen(order.user);
                      }
                    }}
                    className={classes.cellName}
                  >
                    {(order.user && order.user.email) || ''}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <CreateEditOrder
        title={titleModal}
        order={selectOrder}
        isOpen={isCreateEdit}
        isEdit={isEditModal}
        orderInvoiceId={orderInvoiceId}
        handleClose={handleIsCreateEditClose}
        onSuccessPayment={onSuccessPayment}
      />

      <EditUser
        user={selectUser}
        isOpen={isEditUserOpen}
        handleClose={handleIsEditUserClose}
      />

      <SuccessPayment
        isOpen={isSuccessPayment}
        handleClose={handleCloseSuccessPayment}
      />
    </Fragment>
  );
};

OrdersTable.propTypes = propTypes;
OrdersTable.defaultProps = defaultProps;

export default withStyles(styles)(OrdersTable);
