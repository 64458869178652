import React, { useMemo, useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  withStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { firstSignIn } from 'redux/modules/login/loginActions';
import { showNotification } from 'redux/modules/app/appActions';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const styles = () => ({
  col: {
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 26,
  },
  textField: {
    width: '100%',
    marginBottom: 15,
  },
  text: {
    textAlign: 'center',
  },
  button: {
    width: '50%',
    marginTop: 20,
  },
});

const FirstLogin = (props) => {
  const { classes } = props;
  const user = useSelector((state) => state.login.awsUser);
  const [data, setData] = useState({
    email: user && user.username ? user.username : '',
    password: '',
    password2: '',
    showPassword: false,
  });

  const isActiveSubmit = useMemo(() => {
    const regex = new RegExp(
      '^(?=.{8,})(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])',
    );

    return (
      data.password === data.password2 &&
      regex.test(data.password) &&
      regex.test(data.password2)
    );
  }, [data]);

  const dispatch = useDispatch();
  const FirstSignIn = (password) => dispatch(firstSignIn(password));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  const handleChangeData = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleDataClickShowPassword = () => {
    setData({ ...data, showPassword: !data.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUpdate = async () => {
    try {
      await FirstSignIn(data.password);
    } catch (err) {
      console.error('err', err);
      ShowNotification('Error', err.response ? err.response.text : err.message);
    }
  };

  return (
    <div className={classes.col}>
      <Typography className={classes.title} variant="h4" color="secondary">
        Set a new password
      </Typography>
      <TextField
        className={classes.textField}
        onChange={handleChangeData('email')}
        value={data.email}
        disabled
        label="Email"
        variant="outlined"
        color="secondary"
      />

      <FormControl className={classes.textField} variant="outlined">
        <InputLabel id="reset-password-0">Password</InputLabel>
        <OutlinedInput
          type={data.showPassword ? 'text' : 'password'}
          value={data.password}
          color="secondary"
          aria-describedby="reset-password-0"
          onChange={handleChangeData('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleDataClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                color="secondary"
                edge="end"
              >
                {data.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          variant="outlined"
        />
      </FormControl>

      <FormControl className={classes.textField} variant="outlined">
        <InputLabel id="reset-password-1">Repeat your password</InputLabel>
        <OutlinedInput
          type={data.showPassword ? 'text' : 'password'}
          value={data.password2}
          color="secondary"
          onChange={handleChangeData('password2')}
          aria-describedby="reset-password-1"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleDataClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                color="secondary"
                edge="end"
              >
                {data.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Repeat your password"
          variant="outlined"
        />
      </FormControl>

      <Typography className={classes.text} variant="subtitle2">
        The password must contain at least 8 characters, numbers and special
        characters are required
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        size="large"
        color="secondary"
        onClick={handleUpdate}
        disabled={!isActiveSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default withStyles(styles)(FirstLogin);
