import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  withStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { addUserAdmin } from 'redux/modules/users/usersActions';
import { isEmail } from 'helpers/functions';

const propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
};

const styles = () => ({
  modal: {
    maxWidth: '732px',
    width: '732px',
  },
  title: {
    marginBottom: 6,
  },
  field: {
    marginBottom: '18px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  checkbox: {},
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
});

const CreateUserAdmin = (props) => {
  const { isOpen, handleClose, classes } = props;
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  const isActiveCreate = useMemo(() => {
    return Boolean(
      user.first_name.trim() && user.last_name.trim() && isEmail(user.email),
    );
  }, [user]);

  const dispatch = useDispatch();
  const AddUserAdmin = (data) => dispatch(addUserAdmin(data));

  useEffect(() => {
    clearModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const clearModal = () => {
    setUser({
      first_name: '',
      last_name: '',
      email: '',
    });
  };

  const handleChange = (name) => (event) => {
    setUser({ ...user, [name]: event.target.value });
  };

  const handleAddAdminUser = () => {
    AddUserAdmin({
      first_name: user.first_name.trim(),
      last_name: user.last_name.trim(),
      email: user.email.toLowerCase(),
    });
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography className={classes.title} variant="h5">
          Add admin user
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.row}>
          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="First name"
              value={user.first_name}
              className={classes.field}
              onChange={handleChange('first_name')}
              fullWidth
            />

            <TextField
              variant="outlined"
              color="secondary"
              label="Email"
              value={user.email}
              className={classes.field}
              onChange={handleChange('email')}
              fullWidth
            />
          </Grid>

          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="Last name"
              value={user.last_name}
              className={classes.field}
              onChange={handleChange('last_name')}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          disabled={!isActiveCreate}
          onClick={handleAddAdminUser}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateUserAdmin.propTypes = propTypes;
CreateUserAdmin.defaultProps = defaultProps;

export default withStyles(styles)(CreateUserAdmin);
