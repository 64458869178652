import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  withStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  Button,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { generateInvoice } from 'redux/modules/orders/ordersActions';
import useDebounce from 'uikits/useDebounce';

const propTypes = {
  id: PropTypes.string,
  genInvoices: PropTypes.shape({
    description: PropTypes.string,
    paymentType: PropTypes.bool,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
  handleChangeGenInvoices: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  id: '',
  genInvoices: {
    description: '',
    products: [],
    paymentType: false,
  },
};

const styles = () => ({
  container: {},
  field: {
    marginBottom: '18px',
  },
  rowInvoice: {
    marginBottom: 18,
  },
  rowInvoiceInfo: {
    position: 'relative',
  },
  removeBtn: {
    position: 'absolute',
    top: '50%',
    left: '100%',
    marginLeft: -6,
    transform: 'translateY(-50%)',
  },
  addBtnWrp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  textNone: {
    marginBottom: 14,
  },
  inputPrice: {
    paddingRight: 6,
  },
  generateBtn: {
    marginLeft: 14,
  },
});

const TabGenerateInvoice = (props) => {
  const {
    id,
    genInvoices: propsGenInvoices,
    handleChangeGenInvoices,
    handleClose,
    classes,
  } = props;
  const [genInvoices, setGenInvoices] = useState(propsGenInvoices);

  const debouncedDataInvoice = useDebounce(genInvoices, 100);

  const dispatch = useDispatch();
  const GenerateInvoice = (id, data) => dispatch(generateInvoice(id, data));

  useEffect(() => {
    handleChangeGenInvoices(debouncedDataInvoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDataInvoice]);

  const isGenerate = useMemo(() => {
    return Boolean(genInvoices.products.length && genInvoices.description);
  }, [genInvoices]);

  const handleChangeProductInvoice = (index, name) => (event) => {
    const { value } = event.target;
    if (name === 'price' && !/^\d+(?:\.\d{1,2})?$/.test(value)) {
      return;
    }
    setGenInvoices((prevState) => {
      return {
        ...prevState,
        products: prevState.products.map((invoice, i) => {
          if (i !== index) {
            return invoice;
          }
          return { ...invoice, [name]: value };
        }),
      };
    });
  };

  const handleAddProductInvoice = () => {
    setGenInvoices((prevState) => {
      return {
        ...prevState,
        products: prevState.products.concat({
          name: '',
          description: '',
          type: 'product',
          price: '',
        }),
      };
    });
  };

  const handleRemoveProductInvoice = (index) => {
    setGenInvoices((prevState) => {
      return {
        ...prevState,
        products: prevState.products.filter((_, i) => i !== index),
      };
    });
  };

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setGenInvoices((prevState) => {
      return {
        ...prevState,
        description: value,
      };
    });
  };

  const handlePaymentType = () => {
    setGenInvoices((prevState) => {
      const paymentType = !prevState.paymentType;
      return {
        ...prevState,
        paymentType,
      };
    });
  };

  const handleGenerateInvoice = async () => {
    if (
      genInvoices.products.findIndex(
        (product) => product.name.trim() === '',
      ) === -1
    ) {
      try {
        const nPaymentType = genInvoices.paymentType ? 'cash' : 'paypal';
        const isPaid = nPaymentType === 'cash';
        const data = {
          ...genInvoices,
          isPaid,
          products: genInvoices.products.map((p) => ({
            ...p,
            price: +p.price,
          })),
          paymentType: nPaymentType,
        };

        await GenerateInvoice(id, data);
        handleClose();
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className={classes.container}>
      <TextField
        variant="outlined"
        color="secondary"
        label="Description"
        rows={5}
        value={genInvoices.description}
        className={classes.field}
        onChange={handleChangeDescription}
        fullWidth
        multiline
      />

      <Typography className={classes.textNone} variant="subtitle1">
        Products:{' '}
        {!genInvoices.products.length && (
          <Typography component="span" variant="body1">
            no products
          </Typography>
        )}
      </Typography>

      {genInvoices.products.map((invoice, index) => (
        <div className={classes.rowInvoice} key={`gen_invoice_${index}`}>
          <Grid container spacing={2} className={classes.rowInvoiceInfo}>
            <Grid item md={4}>
              <TextField
                variant="outlined"
                color="secondary"
                label="Name"
                value={invoice.name}
                onChange={handleChangeProductInvoice(index, 'name')}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                color="secondary"
                label="Description"
                value={invoice.description}
                onChange={handleChangeProductInvoice(index, 'description')}
                fullWidth
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                variant="outlined"
                color="secondary"
                label="Price"
                value={invoice.price}
                InputProps={{
                  classes: {
                    input: classes.inputPrice,
                  },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                type="number"
                onChange={handleChangeProductInvoice(index, 'price')}
                fullWidth
              />
            </Grid>
            <IconButton
              // disableRipple
              className={classes.removeBtn}
              onClick={() => handleRemoveProductInvoice(index)}
              color="secondary"
              size="small"
            >
              <HighlightOff />
            </IconButton>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={4}>
              <Typography component="span" variant="body1">
                Type:
              </Typography>
            </Grid>
            <Grid item md={8}>
              <RadioGroup
                row
                aria-label="position"
                name="type"
                defaultValue="top"
                value={invoice.type}
                onChange={handleChangeProductInvoice(index, 'type')}
              >
                <FormControlLabel
                  value="product"
                  control={<Radio color="secondary" />}
                  label="Product"
                />
                <FormControlLabel
                  value="service"
                  control={<Radio color="secondary" />}
                  label="Service"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
      ))}

      <div className={classes.addBtnWrp}>
        <FormControlLabel
          control={
            <Checkbox
              checked={genInvoices.paymentType}
              onChange={handlePaymentType}
            />
          }
          label="Paid in cash"
        />
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleAddProductInvoice}
        >
          Add product
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.generateBtn}
          onClick={handleGenerateInvoice}
          disabled={!isGenerate}
        >
          Generate
        </Button>
      </div>
    </div>
  );
};

TabGenerateInvoice.propTypes = propTypes;
TabGenerateInvoice.defaultProps = defaultProps;

export default withStyles(styles)(TabGenerateInvoice);
