import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = () => ({
  content: {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FirstPreloader = (props) => {
  const { classes } = props;

  return (
    <div className={classes.content}>
      <CircularProgress size={52} color="secondary" />
    </div>
  );
};

export default withStyles(styles)(FirstPreloader);
