import React, { useMemo, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authenticate } from 'redux/modules/login/loginActions';
import ROUTES from 'constants/routes';
import PAGES from 'components/pages';
import UserNotification from 'uikits/UserNotification';
import FirstPreloader from 'uikits/FirstPreloader';
import Page from '../MainLayout/Page';
import LoginPage from '../MainLayout/LoginPage';

const App = () => {
  const user = useSelector((state) => state.login.user);
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  const isAdmin = useMemo(() => {
    return Boolean(user.is_admin);
  }, [user]);

  const isSuperAdmin = useMemo(() => {
    return Boolean(user.is_admin && user.is_system_admin);
  }, [user]);

  const dispatch = useDispatch();
  const Authenticate = (data) => dispatch(authenticate(data));

  useEffect(() => {
    Authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated !== null ? (
    [
      <BrowserRouter key="app-router-0">
        <Switch>
          <Route
            exact
            path={ROUTES.ROOT}
            render={() => <Redirect to={ROUTES.ORDERS} />}
          />
          <LoginPage exact path={ROUTES.LOGIN} component={PAGES.LoginPage} />
          <LoginPage exact path={ROUTES.SIGN_UP} component={PAGES.SignUpPage} />
          <LoginPage
            exact
            path={ROUTES.RESTORE_PASSWORD}
            component={PAGES.RestorePage}
          />
          <Page exact path={ROUTES.ORDERS} component={PAGES.OrdersPage} />
          {isAdmin && (
            <Page exact path={ROUTES.CLIENTS} component={PAGES.ClientsPage} />
          )}
          {isSuperAdmin && (
            <Page exact path={ROUTES.ADMINS} component={PAGES.AdminsPage} />
          )}
          {isAdmin && (
            <Page
              exact
              path={ROUTES.EMPLOYEES}
              component={PAGES.EmployeesPage}
            />
          )}
          {isAdmin && (
            <Page exact path={ROUTES.REVIEWS} component={PAGES.ReviewsPage} />
          )}
          {isSuperAdmin && (
            <Page
              exact
              path={ROUTES.STATISTIC}
              component={PAGES.StatisticPage}
            />
          )}
          <Page exact path={ROUTES.PROFILE} component={PAGES.ProfilePage} />
          <Route component={PAGES.NotFoundPage} />
        </Switch>
      </BrowserRouter>,

      <UserNotification key="user-notification-1" />,
    ]
  ) : (
    <FirstPreloader />
  );
};

export default App;
