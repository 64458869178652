import React, { useState } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ROUTES from 'constants/routes';
import { userRegistration } from 'redux/modules/login/loginActions';
import {
  Typography,
  TextField,
  Button,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import { showNotification } from 'redux/modules/app/appActions';

const styles = (theme) => ({
  col: {
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 26,
  },
  textField: {
    width: '100%',
    marginBottom: 15,
  },
  button: {
    width: '50%',
    marginTop: 20,
  },
});

const LoginPage = (props) => {
  const { classes, history } = props;
  const [signUp, setSignUp] = useState({
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    phone_number: '',
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const UserRegistration = (data) => dispatch(userRegistration(data));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  const handleChangeSignUp = (name) => (event) => {
    setSignUp({ ...signUp, [name]: event.target.value });
  };

  const handleRegistration = async () => {
    setLoading(true);
    const data = { ...signUp };
    delete data.address;
    try {
      await UserRegistration(data);

      history.push(ROUTES.LOGIN);
      ShowNotification(
        'Success',
        'You are registered, check your email and try logging in',
      );
    } catch (err) {
      console.error('err', err);
      ShowNotification('Error', err.response ? err.response.text : err.message);
    }
    setLoading(false);
  };

  return (
    <div className={classes.col}>
      <Typography className={classes.title} variant="h3" color="secondary">
        Sign Up
      </Typography>
      <TextField
        className={classes.textField}
        onChange={handleChangeSignUp('first_name')}
        value={signUp.first_name}
        label="First name"
        variant="outlined"
        color="secondary"
      />
      <TextField
        className={classes.textField}
        onChange={handleChangeSignUp('last_name')}
        value={signUp.last_name}
        label="Last name"
        variant="outlined"
        color="secondary"
      />
      <TextField
        className={classes.textField}
        onChange={handleChangeSignUp('email')}
        value={signUp.email}
        name="email"
        label="Email"
        variant="outlined"
        color="secondary"
      />
      <TextField
        className={classes.textField}
        onChange={handleChangeSignUp('address')}
        value={signUp.address}
        label="Address"
        variant="outlined"
        color="secondary"
      />
      <div className={classes.textField}>
        <TextField
          onChange={handleChangeSignUp('phone_number')}
          value={signUp.phone_number}
          label="phone_number"
          variant="outlined"
          color="secondary"
          fullWidth
        />
        {loading && <LinearProgress color="secondary" />}
      </div>

      <Typography className={classes.text} variant="subtitle2">
        If you have an account, try{' '}
        <Typography component={Link} to={ROUTES.LOGIN} color="secondary">
          logging in
        </Typography>
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        size="large"
        color="secondary"
        onClick={handleRegistration}
      >
        Submit
      </Button>
    </div>
  );
};

const enhance = compose(withStyles(styles), withRouter);

export default enhance(LoginPage);
