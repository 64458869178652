import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  nameUsers: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
  nameUsers: [],
};

const styles = () => ({
  modal: {
    width: 600,
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
});

const DeleteConfirmation = (props) => {
  const {
    isOpen,
    title,
    text,
    nameUsers,
    handleClose,
    handleConfirm,
    classes,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          {text}
          {Boolean(nameUsers.length) && (
            <>
              <br />
              {nameUsers.join(', ')}
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmation.propTypes = propTypes;
DeleteConfirmation.defaultProps = defaultProps;

export default withStyles(styles)(DeleteConfirmation);
