import { useState, useEffect } from 'react';

export default function useDebounce(value = '', delay = 0) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return debouncedValue;
}
