export default {
  ROOT: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  RESTORE_PASSWORD: '/restore',
  CLIENTS: '/clients',
  ADMINS: '/admins',
  EMPLOYEES: '/employees',
  ORDERS: '/orders',
  REVIEWS: '/reviews',
  PROFILE: '/profile',
  STATISTIC: '/statistic',
};
