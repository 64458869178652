import moment from 'moment';

export function desc(a, b, orderBy) {
  const steps = orderBy.split('.');
  let valA = '';
  let valB = '';

  if (orderBy !== 'available_time') {
    valA =
      (a.available_time && a.available_time[0] && a.available_time[0].from) ||
      '';
    valB =
      (b.available_time && b.available_time[0] && b.available_time[0].from) ||
      '';
  }
  if (steps.length === 2) {
    valA = (a[steps[0]] && [steps[1]]) || '';
    valB = (b[steps[0]] && [steps[1]]) || '';
  } else {
    valA = a[orderBy];
    valB = b[orderBy];
  }

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export const invokeAll = (...funcs) => (...args) =>
  funcs.forEach((func) => func(...args));

export const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(moment(startDate).format('YYYY-MM-DD'));
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
};
