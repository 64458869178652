import superagent from 'superagent';
import { ActionType } from 'redux-promise-middleware';
import { getSession } from 'helpers/awsAuth';
import {
  GET_REVIEWS,
  CREATE_REVIEW,
  UPDATE_REVIEW,
  DELETE_REVIEW,
} from 'redux/actions/reviews';
import { showNotification } from 'redux/modules/app/appActions';
import config from 'config';

const { CoreURL } = config;

export const getReviews = () => async (dispatch) => {
  dispatch({ type: `${GET_REVIEWS}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get('/api/v1/admin/reviews')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL);

    return dispatch({
      type: `${GET_REVIEWS}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_REVIEWS}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const createReview = (data) => async (dispatch) => {
  dispatch({ type: `${CREATE_REVIEW}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .post('/api/v1/admin/reviews')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(showNotification({ title: 'Success', message: 'Review create' }));

    return dispatch({
      type: `${CREATE_REVIEW}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (err) {
    console.error(err);
    dispatch(showNotification({ title: 'Error', message: 'Failed to create' }));
    return dispatch({
      type: `${CREATE_REVIEW}_${ActionType.Rejected}`,
      payload: err,
    });
  }
};

export const updateReview = (id, data) => async (dispatch) => {
  dispatch({ type: `${UPDATE_REVIEW}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .patch(`/api/v1/admin/reviews/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    dispatch(showNotification({ title: 'Success', message: 'Review updated' }));

    return dispatch({
      type: `${UPDATE_REVIEW}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    dispatch(showNotification({ title: 'Error', message: 'Failed to update' }));
    return dispatch({
      type: `${UPDATE_REVIEW}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const deleteReview = (id, data) => async (dispatch) => {
  dispatch({ type: `${DELETE_REVIEW}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    await superagent
      .delete(`/api/v1/admin/reviews/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .use(CoreURL)
      .send(data);

    return dispatch({
      type: `${DELETE_REVIEW}_${ActionType.Fulfilled}`,
      payload: id,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${DELETE_REVIEW}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};
