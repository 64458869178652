import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { CssBaseline, withStyles } from '@material-ui/core';
import Header from './Header/Header';
import LeftDrawer from './LeftDrawer/LeftDrawer';
import { setLastRoute } from 'redux/modules/app/appActions';

const propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  component: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  exact: false,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    paddingTop: 64,
    minHeight: '100vh',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    maxWidth: 240,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(6),
  },
});

function Page(props) {
  const { path, exact, location, component, classes } = props;
  const [isOpen, setIsOpen] = useState(false);

  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const isFirstSignIn = useSelector((state) => state.login.isFirstSignIn);

  const dispatch = useDispatch();
  const SetLastRoute = (url) => dispatch(setLastRoute(url));

  useEffect(() => {
    SetLastRoute(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return !isAuthenticated || isFirstSignIn ? (
    <Redirect to={ROUTES.LOGIN} />
  ) : (
    <div className={classes.root}>
      <CssBaseline />
      <Header isOpen={isOpen} handleDrawer={handleDrawerToggle} />
      <nav className={classes.drawer}>
        <LeftDrawer isOpen={isOpen} />
      </nav>
      <main className={classes.content}>
        <Route path={path} component={component} exact={exact} />
      </main>
    </div>
  );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

const enhance = compose(withStyles(styles), withRouter);

export default enhance(Page);
