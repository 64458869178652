import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
};

const styles = () => ({
  modal: {
    width: 600,
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
  title: {
    marginBottom: -16,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  btn: {
    display: 'flex',
    margin: '16px auto 40px',
  },
});

const SuccessPayment = (props) => {
  const { isOpen, handleClose, classes } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          The payment is being processed
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" className={classes.text}>
          Payment status will be updated within a few minutes
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.btn}
          onClick={handleClose}
        >
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
};

SuccessPayment.propTypes = propTypes;
SuccessPayment.defaultProps = defaultProps;

export default withStyles(styles)(SuccessPayment);
