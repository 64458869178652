import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  withStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { AccountCircle, Close } from '@material-ui/icons';
import optionsSource from 'constants/optionsSource';
import SHAPES from 'shapes';
import {
  createReview,
  updateReview,
  getReviews,
} from 'redux/modules/reviews/reviewsActions';
import { showNotification } from 'redux/modules/app/appActions';

const propTypes = {
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  review: SHAPES.REVIEW_SHAPE,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
  isEdit: false,
  review: null,
};

const styles = () => ({
  modal: {
    maxWidth: '732px',
    width: '732px',
  },
  title: {
    marginBottom: 6,
  },
  field: {
    marginBottom: '18px',
    // '&:last-of-type': {
    //   marginBottom: 0,
    // },
  },
  fieldAdornment: {
    position: 'relative',
    width: 26,
    height: 26,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  checkbox: {},
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
});

const CreateEditReview = (props) => {
  const { isOpen, review: propsReview, handleClose, isEdit, classes } = props;
  const [review, setReview] = useState({
    reviewerName: '',
    reviewerAvatar: '',
    text: '',
    link: '',
    stars: 1,
    source: 'google',
    is_published: true,
  });

  const titleModal = useMemo(() => {
    if (isEdit) {
      return 'Edit Review';
    }
    return 'Crate Review';
  }, [isEdit]);

  const isDisabledUpdate = useMemo(() => {
    return Boolean(
      review.text.trim().length < 5 || !review.reviewerName.trim(),
    );
  }, [review]);

  const dispatch = useDispatch();
  const GetReviews = () => dispatch(getReviews());
  const UpdateReview = (id, data) => dispatch(updateReview(id, data));
  const CreateReview = (data) => dispatch(createReview(data));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  useEffect(() => {
    clearModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, propsReview]);

  const clearModal = () => {
    setReview({
      reviewerName:
        propsReview && propsReview.reviewer && propsReview.reviewer.name
          ? propsReview.reviewer.name
          : '',
      reviewerAvatar:
        propsReview && propsReview.reviewer && propsReview.reviewer.avatar
          ? propsReview.reviewer.avatar
          : '',
      link: propsReview && propsReview.link ? propsReview.link : '',
      text: propsReview && propsReview.text ? propsReview.text : '',
      stars:
        propsReview && propsReview.stars ? Math.round(propsReview.stars) : 1,
      source: propsReview && propsReview.source ? propsReview.source : 'google',
      is_published:
        propsReview && propsReview.job_title ? propsReview.job_title : true,
    });
  };

  const handleChange = (name) => (event) => {
    setReview({ ...review, [name]: event.target.value });
  };

  const handleToggleAsap = () => {
    setReview({ ...review, is_published: !review.is_published });
  };

  const handleChangeStars = (event) => {
    setReview({ ...review, stars: +event.target.value });
  };

  const handleUpdate = async () => {
    try {
      const dataReview = {
        ...review,
        reviewer: {
          name: review.reviewerName,
          avatar: review.reviewerAvatar,
        },
      };

      delete dataReview.reviewerName;
      delete dataReview.reviewerAvatar;

      if (isEdit) {
        await UpdateReview(propsReview._id, dataReview);
      } else {
        await CreateReview(dataReview);
      }
      closeAfterSending();
    } catch (err) {
      console.error(err);
      ShowNotification('Error', 'Failed to update review');
    }
  };

  const closeAfterSending = () => {
    GetReviews();
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography className={classes.title} variant="h5">
          {titleModal}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.row}>
          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="Reviewer"
              value={review.reviewerName}
              className={classes.field}
              onChange={handleChange('reviewerName')}
              fullWidth
            />

            <TextField
              variant="outlined"
              color="secondary"
              label="Link to the reviewer avatar"
              value={review.reviewerAvatar}
              className={classes.field}
              onChange={handleChange('reviewerAvatar')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div className={classes.fieldAdornment}>
                      <AccountCircle />
                      {review.reviewerAvatar && (
                        <img
                          onError={(e) => {
                            e.target.style.display = 'none';
                          }}
                          key={review.reviewerAvatar}
                          src={review.reviewerAvatar}
                          alt="avatar"
                        />
                      )}
                    </div>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              variant="outlined"
              color="secondary"
              label="Link to the review"
              value={review.link}
              className={classes.field}
              onChange={handleChange('link')}
              fullWidth
            />

            <FormControl>
              <FormLabel>Stars</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={review.stars}
                onChange={handleChangeStars}
              >
                <FormControlLabel value={1} control={<Radio />} label="1" />
                <FormControlLabel value={2} control={<Radio />} label="2" />
                <FormControlLabel value={3} control={<Radio />} label="3" />
                <FormControlLabel value={4} control={<Radio />} label="4" />
                <FormControlLabel value={5} control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item md={6} className={classes.col}>
            <TextField
              variant="outlined"
              color="secondary"
              label="Description"
              rows={5}
              value={review.text}
              className={classes.field}
              onChange={handleChange('text')}
              fullWidth
              multiline
            />

            <TextField
              select
              variant="outlined"
              color="secondary"
              label="Source"
              value={review.source}
              className={classes.field}
              onChange={handleChange('source')}
              fullWidth
            >
              {optionsSource.map((option) => (
                <MenuItem key={option.value} button value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>

            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={review.is_published}
                  onChange={handleToggleAsap}
                />
              }
              label="Published"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleUpdate}
          disabled={isDisabledUpdate}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateEditReview.propTypes = propTypes;
CreateEditReview.defaultProps = defaultProps;

export default withStyles(styles)(CreateEditReview);
