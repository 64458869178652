import {
  LOGIN,
  LOGOUT,
  USER_INFO,
  UPDATE_USER_INFO,
  USER_FORGOT_PASSWORD,
  // USER_FORGOT_PASSWORD_SUBMIT,
  // USER_FORGOT_PASSWORD_SUBMIT_ERROR,
  USER_REGISTRATION,
  USER_AUTHENTICATE,
  USER_AUTHENTICATE_ERROR,
} from 'redux/actions/login';
import { ActionType } from 'redux-promise-middleware';
import { loadState } from './../../store/localStorage';

const initialState = loadState();

const { user: cachedUser = {} } = initialState || {};

const defaultState = {
  awsUser: null,
  user: cachedUser,
  isAuthenticated: null,
  // isAuthenticated: false,
  forgotPasswordData: null,
  isLoading: false,
  isFirstSignIn: false,
  tokens: {
    accessToken: null,
    refreshToken: null,
    expires: 0,
  },
  error: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${USER_REGISTRATION}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${USER_REGISTRATION}_${ActionType.Fulfilled}`: {
      return handleRegister(state, action.payload.body);
    }

    case `${USER_REGISTRATION}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    // case `${LOGIN}_${ActionType.Pending}`:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };

    case LOGIN: {
      return handleLogin(state, action.payload);
    }

    case USER_INFO: {
      return handleUserInfo(state, action.payload);
    }

    case `${UPDATE_USER_INFO}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${UPDATE_USER_INFO}_${ActionType.Fulfilled}`: {
      return handleUpdateUserInfo(state, action.payload);
    }

    case `${UPDATE_USER_INFO}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    case USER_FORGOT_PASSWORD: {
      return handleForgotPassword(state, action.payload);
    }

    // case USER_FORGOT_PASSWORD_SUBMIT:
    //   console.log('USER_FORGOT_PASSWORD_SUBMIT', action.payload);
    //   return {
    //     ...state,
    //     forgotPasswordData: null,
    //     isFirstSignIn: false,
    //     isAuthenticated: false,
    //   }

    case USER_AUTHENTICATE: {
      return handleLogin(state, action.payload);
    }

    case USER_AUTHENTICATE_ERROR:
      return {
        ...state,
        isAuthenticated: false,
      };

    // case `${LOGIN}_${ActionType.Rejected}`:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };

    case LOGOUT: {
      localStorage.clear();
      return {
        ...defaultState,
        user: {},
        isAuthenticated: false,
      };
    }

    default:
      return state;
  }
};

const handleRegister = (state, payload) => {
  // const { email, id } = payload;

  return {
    ...state,
    isLoading: false,
  };
};

const handleLogin = (state, payload) => {
  const isFirstSignIn = payload.challengeName === 'NEW_PASSWORD_REQUIRED';
  const awsUser = isFirstSignIn ? payload : null;

  return {
    ...state,
    isFirstSignIn,
    awsUser,
    isAuthenticated: true,
    isLoading: false,
  };
};

const handleUserInfo = (state, response) => {
  const user = JSON.parse(response.text);

  return {
    ...state,
    user,
  };
};

const handleUpdateUserInfo = (state, response) => {
  const user = JSON.parse(response.text);

  return {
    ...state,
    user: { ...state.user, ...user },
  };
};

const handleForgotPassword = (state, payload) => {
  // const isFirstSignIn = payload.challengeName === 'NEW_PASSWORD_REQUIRED';
  // const awsUser = isFirstSignIn ? payload : null;

  return {
    ...state,
    forgotPasswordData: payload,
    isLoading: false,
  };
};
