import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import OrdersTable from '../containers/Tables/OrdersTable';
import { getMyInfoUser } from 'redux/modules/login/loginActions';
import { getEmployees } from 'redux/modules/employees/employeesActions';

const styles = () => ({});

function OrdersPage(props) {
  const { classes } = props;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const user = useSelector((state) => state.login.user);
  const orders = useSelector((state) => state.orders.orders);

  const isAdmin = useMemo(() => {
    return Boolean(user.is_admin);
  }, [user]);

  const dispatch = useDispatch();
  const GetMyInfoUser = () => dispatch(getMyInfoUser());
  const GetEmployees = () => dispatch(getEmployees());

  useEffect(() => {
    if (isAdmin) {
      GetEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !user._id) {
      GetMyInfoUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOrderBy = (property) => {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  return (
    <DocumentTitle
      title={`CandCords ${
        isAdmin ? 'Admin Dashboard' : 'Personal Account'
      } - Applications`}
    >
      <div className={classes.container}>
        <Paper>
          <OrdersTable
            order={order}
            orderBy={orderBy}
            orders={orders}
            page={page}
            rowsPerPage={rowsPerPage}
            handleOrderBy={handleOrderBy}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </DocumentTitle>
  );
}

export default withStyles(styles)(OrdersPage);
