import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({});

const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    history.push('/');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={classes.container}>not_found_page</div>;
};

export default NotFoundPage;
