import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'redux/reducers';
import getBaseMiddlewares from './baseMiddlewares';
import { loadState } from './localStorage';

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

const initialState = loadState();

const configureStore = () => {
  const enhancer = composeEnhancers(applyMiddleware(...getBaseMiddlewares()));

  return createStore(rootReducer, {}, enhancer, initialState);
};

export default configureStore;
