import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ROUTES from 'constants/routes';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    maxWidth: 240,
  },
  drawerOpen: {
    zIndex: 9,
    paddingTop: 64,
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    paddingTop: 64,
    zIndex: 9,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  navActive: {
    backgroundColor: '#f0f0f0',
  },
  settingPage: {
    marginTop: 24,
  },
}));

const links = [
  {
    to: ROUTES.ORDERS,
    title: 'Orders',
    icon: 'work',
  },
  {
    to: ROUTES.CLIENTS,
    title: 'Clients',
    icon: 'people',
    isAdmin: true,
  },
  {
    to: ROUTES.ADMINS,
    title: 'Admins',
    icon: 'engineering',
    isSuperAdmin: true,
  },
  {
    to: ROUTES.EMPLOYEES,
    title: 'Employees',
    icon: 'supervised_user_circle',
    isAdmin: true,
  },
  {
    to: ROUTES.REVIEWS,
    title: 'Reviews',
    icon: 'comment',
    isAdmin: true,
  },
];

const settingLinks = [
  {
    to: ROUTES.STATISTIC,
    title: 'Statistic',
    icon: 'analytics',
    isSuperAdmin: true,
  },
  {
    to: ROUTES.PROFILE,
    title: 'Profile',
    icon: 'account_box',
  },
];

const LeftDrawer = (props) => {
  const { isOpen } = props;
  const classes = useStyles();

  const user = useSelector((state) => state.login.user);
  const isAdmin = useMemo(() => {
    return Boolean(user.is_admin);
  }, [user]);

  const isSuperAdmin = useMemo(() => {
    return Boolean(user.is_admin && user.is_system_admin);
  }, [user]);

  const drawerClassName = cn(classes.drawer, {
    [classes.drawerOpen]: isOpen,
    [classes.drawerClose]: !isOpen,
  });
  const paperClassName = cn({
    [classes.drawerOpen]: isOpen,
    [classes.drawerClose]: !isOpen,
  });

  return (
    <Drawer
      variant="permanent"
      className={drawerClassName}
      classes={{
        paper: paperClassName,
      }}
      open={isOpen}
    >
      <List>
        {links.map((link) =>
          (!link.isAdmin && !link.isSuperAdmin) ||
          (link.isAdmin && isAdmin) ||
          (link.isSuperAdmin && isSuperAdmin) ? (
            <ListItem
              button
              component={NavLink}
              to={link.to}
              key={link.to}
              activeClassName={classes.navActive}
            >
              <ListItemIcon>
                <Tooltip title={isOpen ? '' : link.title} placement="right">
                  <Icon>{link.icon}</Icon>
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={link.title} />
            </ListItem>
          ) : null,
        )}
      </List>

      <List className={classes.settingPages}>
        {settingLinks.map((link) =>
          !link.isSuperAdmin || isSuperAdmin ? (
            <ListItem
              button
              component={NavLink}
              to={link.to}
              key={link.to}
              activeClassName={classes.navActive}
            >
              <ListItemIcon>
                <Tooltip title={isOpen ? '' : link.title} placement="right">
                  <Icon>{link.icon}</Icon>
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={link.title} />
            </ListItem>
          ) : null,
        )}
      </List>
    </Drawer>
  );
};

LeftDrawer.propTypes = propTypes;

export default LeftDrawer;
