import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_LAST_ROUTE,
} from 'redux/actions/app';

export const setLastRoute = (path) => (dispatch) =>
  dispatch({ type: SET_LAST_ROUTE, payload: path });

export const showNotification = (data) => (dispatch) => {
  const payload = data;
  return dispatch({
    type: SHOW_NOTIFICATION,
    payload,
  });
};

export const hideNotification = () => (dispatch) => {
  return dispatch({
    type: HIDE_NOTIFICATION,
  });
};
