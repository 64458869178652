import prefix from 'superagent-prefix';
import dotenv from 'dotenv';

dotenv.config();

const isProd =
  process.env.REACT_APP_ENV === 'production' ||
  process.env.NODE_ENV === 'production';
const CoreURL = prefix(process.env.REACT_APP_BASE_URL);
const PayPayClientId = isProd
  ? process.env.REACT_APP_PAY_PAL_CLIENT_ID_PROD
  : process.env.REACT_APP_PAY_PAL_CLIENT_ID;
// const CoreURL = prefix(
//   !isProd ? 'http://127.0.0.1:3001' : process.env.REACT_APP_BASE_URL,
// );

const cognito = {
  REGION: 'eu-west-2',
  USER_POOL_ID: isProd
    ? process.env.REACT_APP_USER_POOL_ID_PROD
    : process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID: isProd
    ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_PROD
    : process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  MANDATORY_SIGN_IN: false,
  AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
};

export default {
  isProd,
  CoreURL,
  cognito,
  PayPayClientId,
};
