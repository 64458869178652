import { Auth } from 'aws-amplify';
import config from 'config';
// import http from './http'

const { cognito } = config;

Auth.configure({
  Auth: {
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    userPoolWebClientId: cognito.USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: cognito.MANDATORY_SIGN_IN,
    authenticationFlowType: cognito.AUTHENTICATION_FLOW_TYPE,
  },
});

export const getSession = () => Auth.currentSession();

export const authenticate = () =>
  Auth.currentAuthenticatedUser({ bypassCache: false });

export const signIn = (email, password) => Auth.signIn(email, password);

export const completeNewPassword = (user, newPassword) =>
  Auth.completeNewPassword(user, newPassword);

export const forgotPassword = (email) => Auth.forgotPassword(email);

export const forgotPasswordSubmit = (email, code, new_password) =>
  Auth.forgotPasswordSubmit(email, code, new_password);

export const signOut = () => Auth.signOut();

// export const getMyInfo = () => {
//   return http({
//     url: '/users/me',
//     method: 'GET',
//   })
// }
