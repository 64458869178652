import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LogoIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 38 40" {...props}>
      <path
        d="M18.62 1C9.88 1 2.48727 6.76909.0690899 14.7145H5.97636v-.069C8.15273 9.60182 13.2309 6.11273 19.0691 6.18182c4.4909.06909 8.4291 2.21091 10.9854 5.52728.38.4836 0 1.2091-.6218 1.2091h-9.7763l-4.4909 7.2891 4.4909 7.3236h9.7763c.6218 0 1.0018.7255.6218 1.2436-2.5909 3.3855-6.7363 5.5619-11.3309 5.4928-5.7-.0691-10.57087-3.5582-12.71269-8.4982v-.0346H0C2.34909 33.8182 9.77636 39.76 18.62 39.76 29.3291 39.76 38 31.0891 38 20.38 38 9.67091 29.3291 1 18.62 1z"
        fill="#F68E3A"
      />
      <path
        d="M0 25.769c.38 1.3127.89818 2.5563 1.52 3.7309.86363-1.3127 1.69273-2.5564 2.55636-3.7309H0z"
        fill="#F46F04"
      />
      <path
        d="M5.97643 14.6454C8.15279 9.60176 13.231 6.11267 19.0692 6.18176c1.8309.03454 3.5927.41454 5.1818 1.07091 1.9345-1.10546 3.7654-2.00364 5.4927-2.76364-2.4182-1.69273-5.2509-2.86727-8.3255-3.31636-3.5581.31091-6.9436.69091-9.8454 1.10545C6.35643 4.31631 2.24552 8.53085.345519 13.7818l-.138183.8981H5.97643v-.0345z"
        fill="#F4B989"
      />
      <path
        d="M.0690918 14.7145H5.97636v-.069c1.58909-3.6619 4.73274-6.52914 8.56724-7.77277 2.9019-2.24546 5.7346-4.00728 8.3946-5.38909C21.5564 1.17273 20.1055 1 18.5855 1 9.88 1 2.48727 6.76909.0690918 14.7145z"
        fill="#F46F04"
      />
    </SvgIcon>
  );
};

export default LogoIcon;
