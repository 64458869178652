import { combineReducers } from 'redux';
import app from 'redux/modules/app/appReducer';
import login from 'redux/modules/login/loginReducer';
import users from 'redux/modules/users/usersReducer';
import orders from 'redux/modules/orders/ordersReducer';
import employees from 'redux/modules/employees/employeesReducer';
import reviews from 'redux/modules/reviews/reviewsReducer';
import statistic from 'redux/modules/statistic/statisticReducer';

const rootReducer = combineReducers({
  app,
  login,
  users,
  orders,
  employees,
  reviews,
  statistic,
});

export default rootReducer;
