import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
  Button,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { deleteReview } from 'redux/modules/reviews/reviewsActions';
import { getSorting, stableSort } from 'helpers/functions';
import CreateEditReview from '../Modals/CreateEditReview';
import DeleteConfirmation from './../Modals/DeleteConfirmation';
import SHAPES from 'shapes';

const propTypes = {
  reviews: SHAPES.REVIEWS_SHAPE,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleOrderBy: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  reviews: [],
};

const styles = () => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
  },
  table: {},
  cell: {
    color: '#707070',
  },
  cellName: {
    color: '#707070',
    cursor: 'pointer',
  },
  checkbox: {
    color: '#707070',
  },
  pagination: {
    marginLeft: 'auto',
  },
  btns: {
    marginLeft: 'auto',
  },
  button: {
    marginLeft: 10,
  },
  buttonFilter: {
    marginLeft: 10,
    marginRight: -12,
  },
});

const ReviewsTable = (props) => {
  const {
    classes,
    reviews,
    page,
    rowsPerPage,
    orderBy,
    order,
    handleOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const [selectedIds, setSelectedIds] = useState([]);
  const [isDeleteReviews, setIsDeleteReviews] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [selectReview, setSelectReview] = useState(null);

  const dispatch = useDispatch();
  const DeleteReview = (id) => dispatch(deleteReview(id));

  const isEditModal = useMemo(() => {
    return selectReview && Boolean(selectReview._id);
  }, [selectReview]);

  const handleCreateOpen = () => {
    setIsCreateEdit(true);
    setSelectReview(null);
  };

  const tableReviews = useMemo(() => {
    return stableSort(reviews, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [reviews, order, orderBy, page, rowsPerPage]);

  const nameReviewers = useMemo(() => {
    return selectedIds.map((id) => {
      const foundUser = reviews.find((u) => u._id === id);

      return foundUser && foundUser.reviewer
        ? `${foundUser.reviewer.name}`
        : id;
    });
  }, [reviews, selectedIds]);

  const handleSelectReview = (id) => {
    const isItemSelected = selectedIds.findIndex((uId) => uId === id) !== -1;

    if (isItemSelected) {
      setSelectedIds(selectedIds.filter((uId) => uId !== id));
    } else {
      setSelectedIds(selectedIds.concat(id));
    }
  };

  const handleRemove = () => {
    selectedIds.forEach((id) => {
      DeleteReview(id);
    });
    setSelectedIds([]);
    setIsDeleteReviews(false);
  };

  const handleEditOpen = (review) => {
    setIsCreateEdit(true);
    setSelectReview(review);
  };

  const handleIsEditClose = () => {
    setIsCreateEdit(false);
  };

  const handleIsDeleteReviewsOpen = () => {
    setIsDeleteReviews(true);
  };

  const handleIsDeleteReviewsClose = () => {
    setIsDeleteReviews(false);
  };

  return (
    <Fragment>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          Reviews
        </Typography>

        <div className={classes.btns}>
          {selectedIds.length > 0 && (
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleIsDeleteReviewsOpen}
            >
              {`Delete ${selectedIds.length} reviews`}
            </Button>
          )}

          <Button
            variant="outlined"
            className={classes.button}
            color="secondary"
            onClick={handleCreateOpen}
          >
            Add Review
          </Button>
        </div>
      </Toolbar>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox"></TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'reviewer' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'reviewer'}
                direction={order}
                onClick={() => handleOrderBy('reviewer')}
              >
                Reviewer
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'stars' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'stars'}
                direction={order}
                onClick={() => handleOrderBy('stars')}
              >
                Stars
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'source' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'source'}
                direction={order}
                onClick={() => handleOrderBy('source')}
              >
                Source
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'is_published' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'is_published'}
                direction={order}
                onClick={() => handleOrderBy('is_published')}
              >
                Published
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'created_at' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'created_at'}
                direction={order}
                onClick={() => handleOrderBy('created_at')}
              >
                Create at
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="left"
              padding="default"
              sortDirection={orderBy === 'updated_at' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'updated_at'}
                direction={order}
                onClick={() => handleOrderBy('updated_at')}
              >
                Update at
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableReviews.map((review, index) => {
            const labelId = `review-${index}`;
            const isItemSelected =
              selectedIds.findIndex((id) => id === review._id) !== -1;

            return (
              <TableRow
                className={classes.productRow}
                hover
                role="checkbox"
                tabIndex={-1}
                key={review._id}
                aria-checked={isItemSelected}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    className={classes.checkbox}
                    checked={isItemSelected}
                    onChange={() => handleSelectReview(review._id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(review)}
                  className={classes.cellName}
                >
                  {(review.reviewer && review.reviewer.name) || ''}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(review)}
                  className={classes.cellName}
                >
                  {review.stars}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(review)}
                  className={classes.cellName}
                >
                  {review.source}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(review)}
                  className={classes.cellName}
                >
                  {review.is_published ? 'Yes' : 'No'}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(review)}
                  className={classes.cellName}
                >
                  {moment(review.created_at).format('MM.DD.YYYY hh:mm')}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="default"
                  id={labelId}
                  onClick={() => handleEditOpen(review)}
                  className={classes.cellName}
                >
                  {moment(review.updated_at).format('MM.DD.YYYY hh:mm')}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={reviews.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <CreateEditReview
        isOpen={isCreateEdit}
        isEdit={isEditModal}
        review={selectReview}
        handleClose={handleIsEditClose}
      />

      <DeleteConfirmation
        isOpen={isDeleteReviews}
        title={`Are you sure you want to delete ${nameReviewers.length} reviews?`}
        text={'User reviews will be deleted:'}
        nameUsers={nameReviewers}
        handleClose={handleIsDeleteReviewsClose}
        handleConfirm={handleRemove}
      />
    </Fragment>
  );
};

ReviewsTable.propTypes = propTypes;
ReviewsTable.defaultProps = defaultProps;

export default withStyles(styles)(ReviewsTable);
