import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_LAST_ROUTE,
} from 'redux/actions/app';

const defaultState = {
  lastRoute: '',
  notification: {
    isOpened: false,
    title: '',
    message: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${SET_LAST_ROUTE}`:
      return handleSetLastRoute(state, action.payload);

    case SHOW_NOTIFICATION: {
      return {
        ...state,
        notification: {
          title: action.payload.title,
          message: action.payload.message,
          isOpened: true,
        },
      };
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: {
          title: '',
          message: '',
          isOpened: false,
        },
      };
    }

    default:
      return state;
  }
};

const handleSetLastRoute = (state, lastRoute) => {
  return {
    ...state,
    lastRoute,
  };
};
