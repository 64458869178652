import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      secondary: '#f50057',
      main: '#000000',
      light: '#000000',
      dark: '#000000',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'HelveticaNeue, sans-serif',
  },
  overrides: {
    MuiDialog: {
      root: {
        zIndex: '9992 !important',
      },
      paper: {
        borderRadius: '24px',
        margin: '1.5rem',
        // boxShadow: 'none',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '14px 36px',
      },
    },
    MuiDialogTitle: {
      root: {
        position: 'relative',
        padding: '42px 36px 14px 36px',
        fontSize: '2rem',
        fontWeight: 500,
        color: '#303E4E',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '14px 36px 24px 36px',
      },
    },
    MuiPopover: {
      root: {
        zIndex: '9999 !important',
      },
      paper: {
        boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
        border: '1px solid #E2E8F0',
        borderRadius: '16px',
        marginTop: '0.75rem',
      },
    },
    MuiTabs: {
      root: {
        minHeight: '2.8rem',
        borderBottom: '1px solid #E2E8F0',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minWidth: '3rem !important',
        fontSize: '0.88rem !important',
        minHeight: '2.8rem',
      },
      textColorInherit: {
        color: '#748297',
        opacity: '1',
        fontWeight: '400',
        '&$selected': {
          color: '#303E4E',
          fontWeight: '500',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        // backgroundColor: '#303E4E',
        borderRadius: 6,
      },
      popper: {
        zIndex: 9999,
      },
      popperArrow: {
        '&[x-placement*="bottom"] $arrow': {
          borderTopLeftRadius: '3px',
          marginTop: -4,
        },
        '&[x-placement*="top"] $arrow': {
          borderBottomRightRadius: '3px',
          marginBottom: -4,
        },
        '&[x-placement*="left"] $arrow': {
          borderTopRightRadius: '3px',
          marginRight: -4,
        },
        '&[x-placement*="right"] $arrow': {
          borderBottomLeftRadius: '3px',
          marginLeft: -4,
        },
      },
      arrow: {
        // color: '#303E4E',
        width: '16px !important',
        height: '16px !important',
        // background: '#303E4E',
        transform: 'rotate(45deg)',
        zIndex: '-9',
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        flexDirection: 'row',
      },
    },
  },
});

export default theme;
