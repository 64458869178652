import React, { useState } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  withStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { forgotPassword } from 'redux/modules/login/loginActions';
import { showNotification } from 'redux/modules/app/appActions';
import { forgotPasswordSubmit } from 'helpers/awsAuth';
import ROUTES from 'constants/routes';

const styles = (theme) => ({
  col: {
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 26,
  },
  textField: {
    width: '100%',
    marginBottom: 15,
  },
  button: {
    width: '50%',
    marginTop: 20,
  },
  text: {
    marginBottom: 15,
  },
});

const RestorePage = (props) => {
  const { classes, history } = props;
  const [data, setData] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const forgotPasswordData = useSelector(
    (state) => state.login.forgotPasswordData,
  );

  const dispatch = useDispatch();
  const ForgotPassword = (email) => dispatch(forgotPassword(email));
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));

  const handleChangeData = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleDataClickShowPassword = () => {
    setData({ ...data, showPassword: !data.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgot = () => {
    ForgotPassword(data.email).catch((err) => {
      console.error(err);
      ShowNotification('Error', err.response ? err.response.text : err.message);
    });
  };

  const handleReset = async () => {
    try {
      await forgotPasswordSubmit(data.email, data.code, data.password);

      history.push(ROUTES.LOGIN);
      ShowNotification('Success', 'Password has been reset. Try logging in');
    } catch (err) {
      console.error(err);
      ShowNotification('Error', err.response ? err.response.text : err.message);
    }
  };

  return !forgotPasswordData ? (
    <div className={classes.col}>
      <Typography className={classes.title} variant="h4" color="secondary">
        Reset password
      </Typography>
      <TextField
        className={classes.textField}
        value={data.email}
        onChange={handleChangeData('email')}
        name="email"
        label="Email"
        variant="outlined"
        color="secondary"
      />

      <Button
        variant="contained"
        className={classes.button}
        size="large"
        color="secondary"
        onClick={handleForgot}
      >
        Reset
      </Button>
    </div>
  ) : (
    <div className={classes.col}>
      <Typography className={classes.title} variant="h4" color="secondary">
        Reset password
      </Typography>
      <TextField
        className={classes.textField}
        value={data.email}
        disabled
        name="email"
        label="Email"
        variant="outlined"
        color="secondary"
      />

      <Typography className={classes.text} variant="subtitle2">
        Please type the verification code sent to{' '}
        <span>{forgotPasswordData}</span>
      </Typography>

      <FormControl className={classes.textField} variant="outlined">
        <InputLabel id="reset-password-0">New password</InputLabel>
        <OutlinedInput
          type={data.showPassword ? 'text' : 'password'}
          value={data.password}
          color="secondary"
          aria-describedby="reset-password-0"
          onChange={handleChangeData('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleDataClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                color="secondary"
                edge="end"
              >
                {data.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="New password"
          variant="outlined"
        />
      </FormControl>

      <TextField
        className={classes.textField}
        value={data.code}
        onChange={handleChangeData('code')}
        label="Code"
        variant="outlined"
        color="secondary"
      />

      <Button
        variant="contained"
        className={classes.button}
        size="large"
        color="secondary"
        onClick={handleReset}
      >
        Reset
      </Button>
    </div>
  );
};

const enhance = compose(withStyles(styles), withRouter);

export default enhance(RestorePage);
