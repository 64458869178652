import React, { useState, useMemo, useEffect, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import ReactS3Uploader from 'react-s3-uploader';
import {
  withStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
  Grid,
  TextField,
  Button,
  MenuItem,
  Tabs,
  Tab,
  Popper,
  Grow,
  Paper,
  MenuList,
} from '@material-ui/core';
import { Add, Close, AddAPhoto, HighlightOff } from '@material-ui/icons';
import TabPanel from 'uikits/TabPanel';
import SHAPES from 'shapes';
import optionsStatus from 'constants/optionsStatus';
import optionsCategory from 'constants/optionsCategory';
import {
  getOrders,
  createOrder,
  updateOrder,
  getLinkForQuoteImage,
  viewingAnOrder,
} from 'redux/modules/orders/ordersActions';
import useDebounce from 'uikits/useDebounce';
import TabGenerateInvoice from './TabGenerateInvoice';
import OrderInvoice from './OrderInvoice';
import TimeOrder from './TimeOrder';
import DeleteConfirmation from './../DeleteConfirmation';

const propTypes = {
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  order: SHAPES.ORDER_SHAPE,
  title: PropTypes.string,
  orderInvoiceId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onSuccessPayment: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  isOpen: false,
  isEdit: false,
  title: 'Create Order',
  orderInvoiceId: '',
  order: null,
};

const styles = (theme) => ({
  modal: {
    maxWidth: '732px',
    width: '732px',
  },
  title: {
    marginBottom: 6,
  },
  timeTitle: {
    marginBottom: 6,
  },
  field: {
    marginBottom: '18px',
  },
  checkbox: {
    marginTop: '6px',
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
  tabs: {},
  tab: {
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },
  tabWrapper: {
    padding: '6px 12px',
    position: 'relative',
  },
  fixed: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1.25rem',
      position: 'fixed',
      borderBottom: '1px solid #E2E8F0',
      zIndex: '9',
    },
  },
  indicator: {
    borderRadius: '4px 4px 0px 0px',
  },
  selected: {
    '&:before': {
      height: '2px',
    },
  },
  tabPanel: {
    padding: 0,
    minHeight: '260px',
  },
  detailPanel: {},
  imageWrp: {
    position: 'relative',
    marginBottom: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageContainer: {
    width: 148,
    height: 148,
  },
  image: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: 5,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey['400'],
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  imageEmpty: {
    cursor: 'pointer',
    '&:hover': {
      '& $imageIcon': {
        transform: 'scale(1.1)',
      },
    },
  },
  imageIcon: {
    fontSize: 40,
    color: '#fff',
    transition: '0.3s ease',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-25px 0 0 -25px',
  },
  imageInput: {
    display: 'none',
  },
  imageField: {},
  imageRemove: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  times: {
    display: 'flex',
    marginBottom: 18,
  },
  date: {
    width: '49%',
    marginRight: '2%',
  },
  timesItems: {
    width: '49%',
    display: 'flex',
  },
  time: {
    width: '49%',
    marginRight: '2%',
    '& input': {
      paddingLeft: 14,
      paddingRight: 14,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  removeBtn: {
    position: 'absolute',
    top: '50%',
    left: '100%',
    marginLeft: -6,
    transform: 'translateY(-50%)',
  },
  addBtnWrp: {
    display: 'flex',
  },
  addBtn: {
    marginLeft: 'auto',
  },
  textNone: {
    marginBottom: 14,
  },
});

function a11yProps(index) {
  return {
    id: `detail-tab-${index}`,
    'aria-controls': `detail-tabpanel-${index}`,
  };
}

const CreateEditOrder = (props) => {
  const {
    isOpen,
    title,
    order: propsOrder,
    handleClose,
    onSuccessPayment,
    isEdit,
    orderInvoiceId,
    classes,
  } = props;
  const anchorRef = useRef(null);
  const [tabValue, setTabValue] = useState(0);
  const [images, setImages] = useState([]);
  const [order, setOrder] = useState({
    category: '',
    status: 'new',
    is_asap: false,
    employee_id: '',
    address: '',
    description: '',
  });
  const [available_dates, setAvailable_dates] = useState([]);
  const [genInvoices, setGenInvoices] = useState({
    description: '',
    products: [],
    isPaid: false,
    paymentType: false,
  });
  const [searchOptions, setSearchOptions] = useState({
    types: ['address'],
    componentRestrictions: { country: 'us' },
  });
  const [payPalLoaded, setPayPalLoaded] = useState(false);
  const [geoAddress, setGeoAddress] = useState({});
  const debouncedQuoteAddress = useDebounce(order.address, 1000);
  const [isRejectedOrder, setIsRejectedOrder] = useState(false);

  useEffect(() => {
    if (isOpen && Object.keys(window).includes('paypal')) {
      setPayPalLoaded(true);
    }
  }, [isOpen]);

  useEffect(() => {
    handleGeoAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuoteAddress]);

  useEffect(() => {
    if (isOpen) {
      initSearchOptions();
    }
  }, [isOpen]);

  useEffect(() => {
    // window.callbackPlacesAutocompleteOptions = () => {
    //   initSearchOptions();
    // };

    initSearchOptions();
  }, []);

  const handleGeoAddress = () => {
    if (
      isOpen &&
      typeof window.google !== 'undefined' &&
      debouncedQuoteAddress.length > 4
    ) {
      geocodeByAddress(debouncedQuoteAddress)
        .then((results) => {
          if (results[0]) {
            let post_code = '';
            const foundPostCode = results[0].address_components.find((item) => {
              return (
                Array.isArray(item.types) &&
                item.types.findIndex((type) => type === 'postal_code') !== -1
              );
            });
            if (foundPostCode) {
              post_code = foundPostCode.long_name;
            }
            setGeoAddress({
              address_optional: results[0].formatted_address,
              post_code,
            });
          } else {
            setGeoAddress({});
          }
        })
        .catch((error) => console.error('geocodeByAddress Error', error));
    }
  };
  const initSearchOptions = () => {
    if (typeof window.google !== 'undefined') {
      setSearchOptions(() => ({
        location: new window.google.maps.LatLng(
          40.668580312466574,
          -73.93541164313673,
        ),
        radius: 2000,
        types: ['address'],
        componentRestrictions: { country: 'us' },
      }));
    }
  };

  const onErrorGoogleMaps = (status, clearSuggestions) => {
    console.log('Google Maps API returned error with status:', status);
    clearSuggestions();
  };

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }) => {
    const open = Boolean(loading || suggestions.length);

    return (
      <>
        {/* <InputLabel htmlFor="quote-address">Address: </InputLabel> */}
        <TextField
          {...getInputProps()}
          // placeholder="Address*"
          className={classes.field}
          label="Address"
          variant="outlined"
          color="secondary"
          id="quote-address"
          fullWidth
          disabled={isView} // ???
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 2,
            width: anchorRef.current ? anchorRef.current.offsetWidth : '',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <MenuList id="menu-list-grow">
                  {loading && <MenuItem disabled>Loading...</MenuItem>}
                  {suggestions.map((suggestion) => (
                    <MenuItem
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                      selected={suggestion.active}
                    >
                      {suggestion.description}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const invoices = useMemo(() => {
    return propsOrder && Array.isArray(propsOrder.invoices)
      ? propsOrder.invoices
      : [];
  }, [propsOrder]);

  const isView = useMemo(() => {
    return propsOrder && propsOrder.status !== 'new';
  }, [propsOrder]);

  const isDisabledAddTime = useMemo(() => {
    const isFoundDate =
      available_dates.findIndex((available_date) => {
        return (
          available_date.date === null ||
          available_date.time_from === null ||
          available_date.time_to === null
        );
      }) !== -1;

    return isView || isFoundDate;
  }, [isView, available_dates]);

  // const isNewStatus = useMemo(() => {
  //   return propsOrder && propsOrder.status === 'new';
  // }, [propsOrder]);

  const user = useSelector((state) => state.login.user);
  const employees = useSelector((state) => state.employees.employees);

  const isAdmin = useMemo(() => {
    return Boolean(user.is_admin);
  }, [user]);

  const isDisabledStatus = useMemo(() => {
    if (!isEdit || propsOrder.status === 'rejected') {
      return true;
    }

    if (!isAdmin && propsOrder.status !== 'new') {
      return true;
    }

    if (!isAdmin && propsOrder.status === 'active') {
      return true;
    }

    return (
      propsOrder &&
      ['completed', 'waiting_for_payment'].includes(propsOrder.status)
    );
  }, [isAdmin, isEdit, propsOrder]);

  const isDisabledUpdate = useMemo(() => {
    if (tabValue === 2) {
      return true;
    }
    if (isAdmin) {
      return propsOrder && propsOrder.status === 'completed';
    }
    return isView;
  }, [isAdmin, isView, propsOrder, tabValue]);

  const isDisabledGenInvoices = useMemo(() => {
    const activeStatuses = ['active', 'waiting_for_payment', 'paid'];

    return !(propsOrder && activeStatuses.includes(propsOrder.status));
  }, [propsOrder]);

  const foundIndexFirstInvoice = useMemo(() => {
    return invoices.findIndex(
      (i) => i.payment && i.payment.state === 'created' && !isAdmin,
    );
  }, [invoices, isAdmin]);

  const renderOptionsStatus = useMemo(() => {
    if (propsOrder && propsOrder.status === 'new') {
      return optionsStatus.filter((s) =>
        ['new', isAdmin ? 'active' : '', 'rejected'].includes(s.value),
      );
    }
    if (propsOrder && propsOrder.status === 'active') {
      return optionsStatus.filter((s) =>
        ['active', 'rejected'].includes(s.value),
      );
    }

    if (propsOrder && propsOrder.status === 'paid') {
      return optionsStatus.filter((s) =>
        ['completed', 'paid'].includes(s.value),
      );
    }
    return optionsStatus;
  }, [propsOrder, isAdmin]);

  const dispatch = useDispatch();
  const CreateOrder = (data) => dispatch(createOrder(data));
  const UpdateOrder = (id, data) => dispatch(updateOrder(id, data));
  const GetOrders = async () => dispatch(getOrders());
  const GetLinkForQuoteImage = (data) => dispatch(getLinkForQuoteImage(data));
  const ViewingAnOrder = (id) => dispatch(viewingAnOrder(id));

  useEffect(() => {
    clearModal();

    if (
      isOpen &&
      isAdmin &&
      propsOrder &&
      propsOrder._id &&
      !propsOrder.is_seen
    ) {
      ViewingAnOrder(propsOrder._id);
    }

    if (isOpen && orderInvoiceId) {
      setTabValue(3);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, propsOrder, isAdmin]);

  const clearModal = () => {
    const orderAddress =
      propsOrder && propsOrder.address && propsOrder.address.address_primary
        ? propsOrder.address.address_primary
        : '';
    const userAddress =
      user && user.address && user.address.address_primary
        ? user.address.address_primary
        : '';
    setOrder({
      category: propsOrder && propsOrder.category ? propsOrder.category : '',
      status: propsOrder && propsOrder.status ? propsOrder.status : 'new',
      is_asap: propsOrder && propsOrder.is_asap ? propsOrder.is_asap : false,
      employee_id:
        propsOrder && propsOrder.employee_id ? propsOrder.employee_id : '',
      address: orderAddress || userAddress,
      description:
        propsOrder && propsOrder.description ? propsOrder.description : '',
    });
    setAvailable_dates(
      propsOrder && Array.isArray(propsOrder.available_time)
        ? propsOrder.available_time.map((aTime) => ({
            selected: aTime.selected,
            date: new Date(aTime.from),
            time_from: new Date(aTime.from),
            time_to: new Date(aTime.to),
          }))
        : [],
    );
    setImages(propsOrder && propsOrder.images ? propsOrder.images : []);
    setGenInvoices({
      description: '',
      products: [],
      isPaid: false,
      paymentType: false,
    });
    setGeoAddress({});
    setTabValue(0);
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (name, flag = false) => (event) => {
    if (!flag) {
      setOrder({ ...order, [name]: event.target.value });
    } else {
      setOrder({ ...order, [name]: event });
    }
  };

  const handleAddTime = () => {
    setAvailable_dates(
      available_dates.concat({
        date: null,
        time_from: null,
        time_to: null,
        selected: !Boolean(available_dates.length),
      }),
    );
  };

  const handleChangeDates = (index) => (name, value) => {
    setAvailable_dates((prevState) => {
      return prevState.map((data, i) => {
        if (i !== index) {
          return {
            ...data,
            selected: name === 'selected' ? false : data.selected,
          };
        }
        if (
          name === 'time_from' &&
          Boolean(data.time_to) &&
          +data.time_to < +value
        ) {
          return {
            ...data,
            time_from: data.time_to,
            time_to: value,
          };
        }
        if (
          name === 'time_to' &&
          Boolean(data.time_from) &&
          +data.time_from > +value
        ) {
          return {
            ...data,
            time_from: value,
            time_to: data.time_from,
          };
        }

        return { ...data, [name]: value };
      });
    });
  };

  const handleRemoveTime = (index) => {
    setAvailable_dates((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleRemoveImage = (index) => {
    setImages((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleChangeImages = (index) => (event) => {
    const { value } = event.target;
    setImages((prevState) => {
      const imgs = [...prevState];
      const img = { ...prevState[index] };
      img.description = value;
      imgs[index] = img;
      return imgs;
    });
  };

  const handleAddItemImage = () => {
    setImages((prevState) =>
      prevState.concat({ url: '', description: '', isLoader: false }),
    );
  };

  const handleToggleAsap = () => {
    setOrder({ ...order, is_asap: !order.is_asap });
  };

  const getDateWithTime = (date_main, date_time) => {
    return new Date(
      new Date(date_main).setHours(
        new Date(date_time).getHours(),
        new Date(date_time).getMinutes(),
        0,
      ),
    );
  };

  const handleUpdate = async () => {
    const data = {
      ...order,
      create_account: false,
      email: user.email,
      phone_number: user.phone_number,
      images: images.map((image) => {
        const { url, description } = image;

        return { url, description };
      }),
      available_time: available_dates
        .filter((aTime) => {
          return !Boolean(
            aTime.date === null ||
              aTime.time_from === null ||
              aTime.time_to === null,
          );
        })
        .map((aTime) => ({
          selected: aTime.selected,
          from: getDateWithTime(aTime.date, aTime.time_from),
          to: getDateWithTime(aTime.date, aTime.time_to),
        })),
    };
    delete data.address;

    data.address = {
      address_primary: order.address,
      address_optional: geoAddress.address_optional || '',
      post_code: geoAddress.post_code || '',
      city: 'New York',
      country: 'US',
    };

    try {
      if (isEdit) {
        const nOrder = { ...propsOrder, ...data };
        delete nOrder.user;
        if (propsOrder && propsOrder.status === order.status) {
          delete data.status;
        }

        await UpdateOrder(propsOrder._id, nOrder);
      } else {
        delete data.status;
        await CreateOrder(data);
      }
      closeAfterSending();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateOrder = () => {
    if (order.status === 'rejected') {
      setIsRejectedOrder(true);
    } else {
      handleUpdate();
    }
  };

  const closeAfterSending = () => {
    GetOrders();
    handleClose();
  };

  const getSignedUrl = (index) => (file, callback) => {
    const { name: fileName, type: fileType } = file;
    setImages((prevState) =>
      prevState.map((image, i) => {
        if (i !== index) {
          return image;
        }
        return { ...image, isLoader: true };
      }),
    );

    GetLinkForQuoteImage({
      fileName,
      fileType,
    })
      .then((data) => {
        callback(data.payload);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (index) => (finish) => {
    setImages((prevState) =>
      prevState.map((image, i) => {
        if (i !== index) {
          return image;
        }
        return { ...image, url: finish.publicUrl, isLoader: false };
      }),
    );
  };

  const onError = (index) => (error) => {
    setImages((prevState) =>
      prevState.map((image, i) => {
        if (i !== index) {
          return image;
        }
        return { ...image, isLoader: false };
      }),
    );

    console.error(error);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <IconButton
          disableRipple
          className={classes.closeButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <Tabs
          className={classes.tabs}
          classes={{
            fixed: classes.fixed,
            indicator: classes.indicator,
          }}
          value={tabValue}
          onChange={handleChangeTabs}
          aria-label="detail-tabs"
        >
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label="Details"
            {...a11yProps(tabValue)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label={`Attachments (${images.length})`}
            {...a11yProps(tabValue)}
          />
          {isAdmin && (
            <Tab
              classes={{
                root: classes.tabWrapper,
                wrapper: classes.tab,
                selected: classes.selected,
              }}
              label="Generate invoices"
              {...a11yProps(tabValue)}
              disabled={isDisabledGenInvoices}
            />
          )}
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label="Invoices"
            {...a11yProps(tabValue)}
            disabled={!isEdit}
          />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        <TabPanel
          className={classes.tabPanel}
          value={tabValue}
          index={0}
          id="detail"
        >
          <Grid container spacing={2} className={classes.row}>
            <Grid item md={4}>
              <TextField
                select
                variant="outlined"
                color="secondary"
                label="Category"
                disabled={isView}
                value={order.category}
                className={classes.field}
                onChange={handleChange('category')}
                fullWidth
              >
                {optionsCategory.map((option) => (
                  <MenuItem key={option.value} button value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>

              {isAdmin && (
                <TextField
                  select
                  variant="outlined"
                  color="secondary"
                  label="Employee"
                  disabled={isView || !isAdmin}
                  value={order.employee_id}
                  className={classes.field}
                  onChange={handleChange('employee_id')}
                  fullWidth
                >
                  {employees.map((option) => (
                    <MenuItem key={option._id} button value={option._id}>
                      {option.first_name} {option.last_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                select
                variant="outlined"
                color="secondary"
                label="Status"
                disabled={isDisabledStatus}
                value={order.status}
                className={classes.field}
                onChange={handleChange('status')}
                fullWidth
              >
                {renderOptionsStatus.map((option) => (
                  <MenuItem key={option.value} button value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>

              <FormControlLabel
                className={classes.checkbox}
                disabled={isView}
                control={
                  <Checkbox
                    checked={order.is_asap}
                    onChange={handleToggleAsap}
                  />
                }
                label="ASAP"
              />
            </Grid>

            <Grid item md={8}>
              {/* <TextField
                variant="outlined"
                color="secondary"
                label="Address"
                disabled={isView}
                value={order.address}
                className={classes.field}
                onChange={handleChange('address')}
                fullWidth
              /> */}

              <PlacesAutocomplete
                value={order.address}
                onChange={handleChange('address', true)}
                onSelect={handleChange('address', true)}
                onError={onErrorGoogleMaps}
                shouldFetchSuggestions={order.address.length > 3}
                // googleCallbackName="callbackPlacesAutocomplete"
                debounce={500}
                searchOptions={searchOptions}
              >
                {renderFunc}
              </PlacesAutocomplete>

              {isAdmin && (
                <TextField
                  variant="outlined"
                  color="secondary"
                  label="Google api address"
                  disabled
                  value={geoAddress.address_optional || ''}
                  className={classes.field}
                  fullWidth
                />
              )}

              <TextField
                variant="outlined"
                color="secondary"
                label="Description"
                rows={5}
                disabled={isView}
                value={order.description}
                className={classes.field}
                onChange={handleChange('description')}
                fullWidth
                multiline
              />

              {available_dates.length > 0 && (
                <Typography variant="body1" className={classes.timeTitle}>
                  Available time periods
                </Typography>
              )}
              {available_dates.map((data, index) => (
                <TimeOrder
                  date={data.date}
                  time_from={data.time_from}
                  time_to={data.time_to}
                  selected={data.selected}
                  isView={isView}
                  isAdmin={isAdmin}
                  handleChange={handleChangeDates(index)}
                  handleRemove={() => handleRemoveTime(index)}
                  key={`time_order_${index}`}
                />
              ))}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          className={classes.tabPanel}
          value={tabValue}
          index={1}
          id="attachments"
        >
          {images.map((img, i) => (
            <Grid
              container
              spacing={2}
              className={classes.imageWrp}
              key={`image_${img.url || i}`}
            >
              <Grid item className={classes.imageContainer}>
                <label
                  className={cn(
                    classes.image,
                    !img.url && !img.isLoader && classes.imageEmpty,
                  )}
                >
                  {!img.url && !img.isLoader && (
                    <ReactS3Uploader
                      className={classes.imageInput}
                      getSignedUrl={getSignedUrl(i)}
                      accept="image/jpeg, image/png"
                      onProgress={() => {}}
                      onError={onError(i)}
                      onFinish={onFinish(i)}
                      contentDisposition="auto"
                    />
                  )}
                  <AddAPhoto className={classes.imageIcon} />
                  {img.isLoader && (
                    <CircularProgress
                      className={classes.progress}
                      color="secondary"
                      size={50}
                    />
                  )}
                  {img.url && <img src={img.url} alt="" />}
                </label>
              </Grid>
              <Grid item md={true}>
                <TextField
                  variant="outlined"
                  color="secondary"
                  label="Description"
                  rows={5}
                  disabled={isView}
                  value={img.description || ''}
                  className={classes.imageField}
                  onChange={handleChangeImages(i)}
                  fullWidth
                  multiline
                />
              </Grid>
              <IconButton
                // disableRipple
                className={classes.removeBtn}
                onClick={() => handleRemoveImage(i)}
                color="secondary"
                size="small"
                disabled={isView}
              >
                <HighlightOff />
              </IconButton>
            </Grid>
          ))}

          {!images.length && (
            <Typography className={classes.textNone} variant="body1">
              There are no attachments in the order
            </Typography>
          )}

          <div className={classes.addBtnWrp}>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              className={classes.addBtn}
              onClick={handleAddItemImage}
              disabled={isView}
            >
              Add attachment
            </Button>
          </div>
        </TabPanel>
        {isAdmin && (
          <TabPanel
            className={classes.tabPanel}
            value={tabValue}
            index={2}
            id="generate_invoices"
          >
            <TabGenerateInvoice
              id={(propsOrder && propsOrder._id) || ''}
              genInvoices={genInvoices}
              handleChangeGenInvoices={setGenInvoices}
              handleClose={closeAfterSending}
            />
          </TabPanel>
        )}
        <TabPanel
          className={classes.tabPanel}
          value={tabValue}
          index={!isAdmin ? 2 : 3}
          id="invoices"
        >
          {!invoices.length ? (
            <Typography className={classes.textNone} variant="body1">
              There is no invoice in the order
            </Typography>
          ) : (
            invoices.map((invoice, index) => (
              <OrderInvoice
                invoice={invoice}
                isAdmin={isAdmin}
                handleClose={closeAfterSending}
                onButtonReady={() => {
                  setPayPalLoaded(true);
                }}
                onSuccessPayment={onSuccessPayment}
                initLoadPayPal={foundIndexFirstInvoice === index}
                payPalLoaded={payPalLoaded}
                isSelectedInvoice={orderInvoiceId === invoice._id}
                key={invoice._id}
              />
            ))
          )}
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleAddTime}
          disabled={isDisabledAddTime}
        >
          Add time
          <Add style={{ marginLeft: 4 }} />
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleUpdateOrder}
          disabled={isDisabledUpdate}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </DialogActions>

      <DeleteConfirmation
        isOpen={isRejectedOrder}
        title={'Order rejection'}
        text={'Are you sure you want to reject the order?'}
        nameUsers={[]}
        handleClose={() => setIsRejectedOrder(false)}
        handleConfirm={() => {
          setIsRejectedOrder(false);
          handleUpdate();
        }}
      />
    </Dialog>
  );
};

CreateEditOrder.propTypes = propTypes;
CreateEditOrder.defaultProps = defaultProps;

export default withStyles(styles)(CreateEditOrder);
