import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PayPalButton } from 'react-paypal-button-v2';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import {
  withStyles,
  Typography,
  IconButton,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { Email, Visibility, GetApp } from '@material-ui/icons';
import { showNotification } from 'redux/modules/app/appActions';
import { sendInvoice } from 'redux/modules/orders/ordersActions';
import config from 'config';

const { PayPayClientId } = config;

const propTypes = {
  invoice: PropTypes.shape({
    pdfUrl: PropTypes.string,
    viewUrl: PropTypes.string,
    payment: PropTypes.shape({
      amount: PropTypes.number,
      totalAmount: PropTypes.number,
      totalTax: PropTypes.number,
      state: PropTypes.string,
      type: PropTypes.string,
      description: PropTypes.string,
    }),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
      }),
    ),
  }),
  isSelectedInvoice: PropTypes.bool,
  payPalLoaded: PropTypes.bool.isRequired,
  initLoadPayPal: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  onButtonReady: PropTypes.func.isRequired,
  onSuccessPayment: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  invoice: {
    pdfUrl: '',
    viewUrl: '',
    payment: {
      amount: 0,
      totalAmount: 0,
      totalTax: 0,
      state: '',
      type: '',
      description: '',
    },
    products: [],
  },
  isSelectedInvoice: false,
  isAdmin: false,
};

const styles = () => ({
  container: {
    position: 'relative',
    padding: 16,
    border: '1px solid #E2E8F0',
    borderRadius: 16,
    marginBottom: 16,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  containerSelected: {
    borderColor: '#f50057',
  },
  head: {
    marginBottom: 12,
  },
  description: {
    paddingRight: 130,
  },
  productsWrp: {
    marginTop: 12,
    marginBottom: 16,
  },
  totalAmount: {},
  btns: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  btn: {
    // fontSize: '2rem',
  },
  closeIcon: {
    fontSize: 24,
  },
  tableHead: {
    borderBottom: '1px solid #E2E8F0',
    margin: '4px 0',
  },
  paymentBtnWrp: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  paymentBtn: {
    '& svg': {
      marginRight: 8,
    },
  },
});

const OrderInvoice = (props) => {
  const {
    invoice,
    isAdmin,
    payPalLoaded,
    initLoadPayPal,
    handleClose,
    onSuccessPayment,
    onButtonReady,
    isSelectedInvoice,
    classes,
  } = props;
  const {
    payment = {},
    viewUrl = '',
    pdfUrl = '',
    products = [],
    _id: invoice_id = '',
  } = invoice;
  const {
    amount = 0,
    totalAmount = 0,
    totalTax = 0,
    state = '',
    type = '',
    description = '',
  } = payment;
  const [isSendInvoice, setIsSendInvoice] = useState(false);

  const dispatch = useDispatch();
  const ShowNotification = (title, message) =>
    dispatch(showNotification({ title, message }));
  const SendInvoice = (id) => dispatch(sendInvoice(id));

  const handleSendInvoice = async () => {
    const result = await SendInvoice(invoice_id);
    const { payload = {} } = result;
    if (payload.status) {
      setIsSendInvoice(true);

      setTimeout(() => {
        setIsSendInvoice(false);
      }, 10000);
    }
  };

  const handleOpenPage = (url) => {
    window.open(url);
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          invoice_id,
          description,
          amount: {
            value: totalAmount,
            currency_code: 'USD',
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: amount,
              },
              tax_total: {
                currency_code: 'USD',
                value: totalTax,
              },
            },
          },
          items: products.map((product) => ({
            unit_amount: {
              currency_code: 'USD',
              value: product.price,
            },
            tax: {
              currency_code: 'USD',
              value: product.tax,
            },
            quantity: 1,
            description: product.description,
            name: product.name,
          })),
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    // Capture the funds from the transaction
    return actions.order.capture().then((details) => {
      // Show a success message to your buyer
      // console.log('onApprove', details);
      // alert(
      //   'onApprove, Transaction completed by ' + details.payer.name.given_name,
      // );
    });
  };

  const onSuccess = (details, data) => {
    ShowNotification('Success', 'Payment was successful');
    handleClose();
    onSuccessPayment();
  };

  const onError = (err) => {
    console.error(err);
    ShowNotification('Error', 'The payment was made with an error');
  };

  return (
    <div
      className={cn(
        classes.container,
        isSelectedInvoice && classes.containerSelected,
      )}
    >
      <div className={classes.head}>
        <Typography variant="body1" className={classes.description}>
          Description: {description}
        </Typography>
        <Typography variant="body1">Payment status: {state}</Typography>
        <Typography variant="body1">Payment type: {type}</Typography>
      </div>

      <Typography variant="body1">Products:</Typography>

      <div className={classes.productsWrp}>
        <div className={classes.tableHead}>
          <Grid container spacing={1}>
            <Grid item md={2}>
              <Typography variant="subtitle2">Name:</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2">Type:</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="subtitle2">Description:</Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle2">Price:</Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle2">Tax:</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2">Amount:</Typography>
            </Grid>
          </Grid>
        </div>

        {products.map((product) => (
          <Grid container spacing={1} key={product.name}>
            <Grid item md={2}>
              <Typography variant="body2">{product.name}</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="body2">{product.type}</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2">{product.description}</Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="body2">${product.price}</Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="body2">${product.tax}</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="body2">${product.amount}</Typography>
            </Grid>
          </Grid>
        ))}
      </div>

      <div className={classes.totalAmount}>
        <div className={classes.tableHead}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Typography variant="subtitle2">Amount:</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="subtitle2">Total Tax:</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="subtitle2">Total amount:</Typography>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1}>
          <Grid item md={6}>
            <Typography variant="body1">${amount}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body1">${totalTax}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body1">${totalAmount}</Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.btns}>
        {isAdmin && (
          <Tooltip title="Send it to the customer">
            <IconButton
              onClick={() => handleSendInvoice()}
              color="secondary"
              disabled={isSendInvoice}
            >
              <Email className={classes.btnIcon} />
            </IconButton>
          </Tooltip>
        )}
        {viewUrl && (
          <Tooltip title="View invoice">
            <IconButton
              onClick={() => handleOpenPage(viewUrl)}
              color="secondary"
            >
              <Visibility className={classes.btnIcon} />
            </IconButton>
          </Tooltip>
        )}
        {pdfUrl && (
          <Tooltip title="Download invoice">
            <IconButton
              onClick={() => handleOpenPage(pdfUrl)}
              color="secondary"
            >
              <GetApp className={classes.btnIcon} />
            </IconButton>
          </Tooltip>
        )}
      </div>

      {state === 'created' && !isAdmin && (initLoadPayPal || payPalLoaded) && (
        <div className={classes.paymentBtnWrp}>
          <PayPalButton
            amount={totalAmount}
            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
            onSuccess={onSuccess}
            createOrder={createOrder}
            onError={onError}
            onButtonReady={onButtonReady}
            onApprove={onApprove}
            options={{
              clientId: PayPayClientId,
            }}
          />
        </div>
      )}
    </div>
  );
};

OrderInvoice.propTypes = propTypes;
OrderInvoice.defaultProps = defaultProps;

export default withStyles(styles)(OrderInvoice);
