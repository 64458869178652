import superagent from 'superagent';
import { ActionType } from 'redux-promise-middleware';
import { getSession } from 'helpers/awsAuth';
import {
  GET_SUMMARY_STATISTIC,
  GET_EARNINGS_STATISTIC,
} from 'redux/actions/statistic';
// import { showNotification } from 'redux/modules/app/appActions';
import config from 'config';

const { CoreURL } = config;

export const getSummaryStatistic = (query = {}) => async (dispatch) => {
  dispatch({ type: `${GET_SUMMARY_STATISTIC}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get('/api/v1/system_admin/statistics/summary')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .query(query)
      .use(CoreURL);

    return dispatch({
      type: `${GET_SUMMARY_STATISTIC}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_SUMMARY_STATISTIC}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};

export const getEarningsStatistic = (query = {}) => async (dispatch) => {
  dispatch({ type: `${GET_EARNINGS_STATISTIC}_${ActionType.Pending}` });

  try {
    const session = await getSession();
    const token = session.idToken.jwtToken;
    const result = await superagent
      .get('/api/v1/system_admin/statistics/earnings')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .query(query)
      .use(CoreURL);

    return dispatch({
      type: `${GET_EARNINGS_STATISTIC}_${ActionType.Fulfilled}`,
      payload: result,
    });
  } catch (e) {
    console.error(e);
    return dispatch({
      type: `${GET_EARNINGS_STATISTIC}_${ActionType.Rejected}`,
      payload: e,
    });
  }
};
