const data = [
  {
    value: 'google',
    text: 'Google',
  },
  {
    value: 'trustpilot',
    text: 'Trustpilot',
  },
];

export default data;
