import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Icon,
  IconButton,
  Button,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Web } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'redux/modules/login/loginActions';
import Logo from 'uikits/Logo';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    borderBottom: '1px solid #F0F0F0',
    zIndex: 999,
    marginLeft: 240,
    padding: '0 24px 0 0 !important',
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: '#333',
    marginLeft: '-20px',
  },
  add: {
    marginRight: 20,
  },
  logo: {
    display: 'inline-flex',
    marginLeft: 6,
    textDecoration: 'none',
  },
  admin: {
    marginLeft: 26,
    paddingLeft: 20,
    borderLeft: '1px solid #707070',
  },
  website: {
    marginRight: 32,
    '& svg': {
      marginRight: 8,
    },
  },
  logout: {
    minWidth: 44,
    width: 44,
    height: 44,
    padding: 0,
    borderRadius: '50%',
  },
}));

const Header = (props) => {
  const { isOpen, handleDrawer } = props;
  const classes = useStyles();

  const user = useSelector((state) => state.login.user);

  const isAdmin = useMemo(() => {
    return Boolean(user.is_admin);
  }, [user]);

  const dispatch = useDispatch();
  const handleLogout = () => dispatch(logout());

  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar>
        <Tooltip title={isOpen ? 'Close' : 'Open'}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawer}
            className={classes.menuButton}
          >
            <Icon>{isOpen ? 'arrow_back' : 'menu'}</Icon>
          </IconButton>
        </Tooltip>
        <Link to="/" className={classes.logo}>
          <Logo />
        </Link>
        {isAdmin && (
          <div className={classes.admin}>
            <Typography className={classes.title} variant="h6">
              Admin
            </Typography>
          </div>
        )}
        <Box ml="auto" display="flex" alignItems="center" color="black">
          <Button
            variant="contained"
            size="large"
            color="secondary"
            href="https://candcords.com/"
            target="_black"
            className={classes.website}
          >
            <Web />
            Go back to website
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={handleLogout}
            className={classes.logout}
          >
            <ExitToApp />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = propTypes;

export default Header;
