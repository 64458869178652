export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const USER_INFO = 'USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const USER_AUTHENTICATE = 'USER_AUTHENTICATE';
export const USER_AUTHENTICATE_ERROR = 'USER_AUTHENTICATE_ERROR';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUBMIT = 'USER_FORGOT_PASSWORD_SUBMIT';
export const USER_FORGOT_PASSWORD_SUBMIT_ERROR =
  'USER_FORGOT_PASSWORD_SUBMIT_ERROR';
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN';
