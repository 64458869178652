import LoginPage from './LoginPages/LoginPage';
import SignUpPage from './LoginPages/SignUpPage';
import RestorePage from './LoginPages/RestorePage';
import NotFoundPage from './NotFoundPage';
import HomePage from './HomePage';
import ClientsPage from './ClientsPage';
import AdminsPage from './AdminsPage';
import EmployeesPage from './EmployeesPage';
import OrdersPage from './OrdersPage';
import ReviewsPage from './ReviewsPage';
import ProfilePage from './ProfilePage';
import StatisticPage from './StatisticPage';

export default {
  LoginPage,
  SignUpPage,
  RestorePage,
  NotFoundPage,
  HomePage,
  ClientsPage,
  AdminsPage,
  EmployeesPage,
  OrdersPage,
  ReviewsPage,
  ProfilePage,
  StatisticPage,
};
