import { LOGOUT } from 'redux/actions/login';
import {
  GET_SUMMARY_STATISTIC,
  GET_EARNINGS_STATISTIC,
} from 'redux/actions/statistic';
import { ActionType } from 'redux-promise-middleware';

const defaultState = {
  summary: [],
  earnings: [],
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_SUMMARY_STATISTIC}_${ActionType.Pending}`:
    case `${GET_EARNINGS_STATISTIC}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${GET_SUMMARY_STATISTIC}_${ActionType.Rejected}`:
    case `${GET_EARNINGS_STATISTIC}_${ActionType.Rejected}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${GET_SUMMARY_STATISTIC}_${ActionType.Fulfilled}`: {
      const { summary = [] } = JSON.parse(
        action.payload.text || '{summary: []}',
      );

      return {
        ...state,
        summary,
        isLoading: false,
      };
    }

    case `${GET_EARNINGS_STATISTIC}_${ActionType.Fulfilled}`: {
      const { earnings = [] } = JSON.parse(
        action.payload.text || '{earnings: []}',
      );

      return {
        ...state,
        earnings,
        isLoading: false,
      };
    }

    case LOGOUT: {
      return {
        ...defaultState,
      };
    }

    default:
      return state;
  }
};
