import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EmployeesTable from '../containers/Tables/EmployeesTable';
import { getEmployees } from 'redux/modules/employees/employeesActions';

const styles = () => ({});

function EmployeesPage(props) {
  const { classes } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('first_name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const employees = useSelector((state) => state.employees.employees);

  const dispatch = useDispatch();
  const GetEmployees = () => dispatch(getEmployees());

  useEffect(() => {
    GetEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOrderBy = (property) => {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  return (
    <DocumentTitle title="CandCords Admin Dashboard - Employees">
      <div className={classes.container}>
        <Paper>
          <EmployeesTable
            order={order}
            orderBy={orderBy}
            users={employees}
            page={page}
            rowsPerPage={rowsPerPage}
            handleOrderBy={handleOrderBy}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </DocumentTitle>
  );
}

export default withStyles(styles)(EmployeesPage);
