import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, id, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

export default TabPanel;
