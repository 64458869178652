const data = [
  {
    value: 'electrician',
    text: 'Electrician',
  },
  {
    value: 'plumbing',
    text: 'Plumbing',
  },
  {
    value: 'handyman',
    text: 'Handyman',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

export default data;
